import { useMemo } from "react";
import download from "../../../assets/dashboard/Combined Shape.svg";
import maps from "../../../assets/dashboard/Maps.svg";
import bharat from "../../../assets/flag/india.svg";
import Heading from "../../../components/Headers/DashboardHeader";
import SalesRatio from "../../Dashboard/SalesRatio";
import { useGetGA4CountriesQuery } from "../../../features/analytics/analyticsApiSlice";

export default function TopSalesLocations({ filter = null }) {
  const { data } = useGetGA4CountriesQuery(filter, { skip: !filter });

  const salesData = useMemo(() => {
    const array = (data ?? []).map(({ country, currentValue, pastValue }) => ({
      percent: Math.round((currentValue / (currentValue + pastValue)) * 100),
      image: bharat,
      name: country,
      amount: currentValue,
      ratio: `+${Math.round((currentValue / (currentValue + pastValue)) * 100)}%`,
    }));
    return array;
  }, [data]);

  return (
    <div className="bg-button-3 border-grey-5 px-3 py-3 rounded-8 mt-4">
      <Heading
        heading={"Top Sales Location"}
        // viewMore={"Download Reports"}
        // image={download}
      />
      <div className="d-flex row justify-content-between">
        <div className="col-7">
          <img
            alt="maps"
            src={maps}
            width={330}
            height={200}
            className="mt-3"
          />
        </div>
        <div className="col-5 mt-4">
          {salesData.map((item) => (
            <SalesRatio
              percent={item.percent}
              image={item.image}
              name={item.name}
              amount={item.amount}
              ratio={item.ratio}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
