import apiSlice from "../../../app/api/apiSlice";

import { omitNullishKeys } from "../../../utils/helper";

export const productApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: ({ queries, exclude }) => {
        return {
          url: "/products",
          params: omitNullishKeys(queries, exclude),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["Products"],
    }),
    getAllProductStatusCount: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }
        return {
          url: `/products/count${queryString}`,
        };
      },
      providesTags: ["Products"],
    }),
    getSingleProduct: builder.query({
      query: ({ queries, id }) => {
        return {
          url: `/products/${id}`,
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["SingleProduct"],
    }),
    checkProductExists: builder.query({
      query: (params) => ({
        url: "/products/isExist",
        params: omitNullishKeys(params),
      }),
      transformResponse: (res) => res.data,
    }),
    getProductsByIds: builder.query({
      query: (productIds = []) => ({
        url: "/products/getProductData",
        method: "POST",
        body: { productIds },
      }),
      transformResponse: (res) => res.data,
      providesTags: ["Products"],
    }),
    getWishlistProducts: builder.query({
      query: (params) => ({
        url: "/product/wishlists",
        params: omitNullishKeys(params),
      }),
      transformResponse: (res) => res.data,
      providesTags: ["Products"],
    }),
    updateWishlistProduct: builder.mutation({
      query: ({ id, details }) => ({
        url: `/product/wishlists/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["Products", "Logs"],
    }),
    updateSelectedDiscount: builder.mutation({
      query: (details) => ({
        url: `/products/bulkAddDiscount`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["Products", "Logs"],
    }),
    updateWishlistProducts: builder.mutation({
      query: ({ id, details }) => ({
        url: `/product/wishlists/removeItems/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["Products", "Logs"],
    }),
    createProduct: builder.mutation({
      query: (productDetails) => ({
        url: "/products",
        method: "POST",
        body: productDetails,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: [
        "DynamicTabs",
        "Products",
        "Shipping",
        "SingleProduct",
        "Inventory",
        "Logs",
      ],
    }),
    updateProduct: builder.mutation({
      query: ({ details, id }) => ({
        url: `/products/${id}`,
        method: "PUT",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: [
        "DynamicTabs",
        "Products",
        "Shipping",
        "SingleProduct",
        "Inventory",
        "Logs",
      ],
    }),
    duplicateProduct: builder.mutation({
      query: ({ details, id }) => ({
        url: `products/duplicate/${id}`,
        method: "POST",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: [
        "DynamicTabs",
        "Products",
        "Shipping",
        "SingleProduct",
        "Inventory",
        "Logs",
      ],
    }),
    bulkEditProduct: builder.mutation({
      query: (updates) => ({
        url: `/products/bulkUpdate`,
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: [
        "DynamicTabs",
        "Products",
        "Shipping",
        "SingleProduct",
        "Inventory",
        "Logs",
      ],
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/products/${id}`,
        method: "DELETE",
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: [
        "DynamicTabs",
        "Products",
        "Shipping",
        "SingleProduct",
        "Inventory",
        "Logs",
      ],
    }),
    bulkDeleteProduct: builder.mutation({
      query: (deletes) => ({
        url: `/products/bulkDelete`,
        method: "DELETE",
        body: deletes,
      }),
      invalidatesTags: [
        "DynamicTabs",
        "Products",
        "Shipping",
        "SingleProduct",
        "Inventory",
        "Logs",
      ],
    }),
    bulkAddRemoveTagManager: builder.mutation({
      query: (details) => ({
        url: `/products/addTagManager`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: [
        "DynamicTabs",
        "Products",
        "Shipping",
        "SingleProduct",
        "Inventory",
        "Logs",
      ],
    }),
    bulkAddRemoveCollections: builder.mutation({
      query: (details) => ({
        url: `/products/addCollection`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: [
        "DynamicTabs",
        "Products",
        "Shipping",
        "SingleProduct",
        "Inventory",
        "Logs",
      ],
    }),
    getDynamicTabs: builder.query({
      query: (queries) => {
        return {
          url: "/products/dynamicTab",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["DynamicTabs"],
    }),
    createDynamicTabs: builder.mutation({
      query: (productDetails) => ({
        url: "/products/dynamicTab",
        method: "POST",
        body: productDetails,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: [
        "DynamicTabs",
        "Products",
        "Shipping",
        "SingleProduct",
        "Inventory",
        "Logs",
      ],
    }),
    updateDynamicTabs: builder.mutation({
      query: (productDetails) => ({
        url: "/products/dynamicTab/bulkUpdate",
        method: "PUT",
        body: productDetails,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: [
        "DynamicTabs",
        "Products",
        "Shipping",
        "SingleProduct",
        "Inventory",
        "Logs",
      ],
    }),
    getShipping: builder.query({
      query: (queries) => {
        return {
          url: "/products/shipping",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["Shipping"],
    }),
    createShipping: builder.mutation({
      query: (shippingDetails) => ({
        url: "/products/shipping",
        method: "POST",
        body: shippingDetails,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: [
        "DynamicTabs",
        "Products",
        "Shipping",
        "SingleProduct",
        "Inventory",
        "Logs",
      ],
    }),
    updateShipping: builder.mutation({
      query: ({ details, id }) => ({
        url: `/products/shipping/${id}`,
        method: "PUT",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: [
        "DynamicTabs",
        "Products",
        "Shipping",
        "SingleProduct",
        "Inventory",
        "Logs",
      ],
    }),
    bulkLabelBadgeUpdate: builder.mutation({
      query: (body) => ({
        url: "/products/bulkAddLabels",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Products", "SingleProduct", "LabelsBadges", "Logs"],
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useGetAllProductStatusCountQuery,
  useGetSingleProductQuery,
  useCheckProductExistsQuery,
  useGetProductsByIdsQuery,
  useGetWishlistProductsQuery,
  useUpdateWishlistProductMutation,
  useUpdateWishlistProductsMutation,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDuplicateProductMutation,
  useBulkEditProductMutation,
  useDeleteProductMutation,
  useBulkDeleteProductMutation,
  useBulkAddRemoveTagManagerMutation,
  useBulkAddRemoveCollectionsMutation,
  useGetDynamicTabsQuery,
  useCreateDynamicTabsMutation,
  useUpdateDynamicTabsMutation,
  useCreateShippingMutation,
  useGetShippingQuery,
  useUpdateShippingMutation,
  useUpdateSelectedDiscountMutation,
  useBulkLabelBadgeUpdateMutation,
} = productApiSlice;
