import { configureStore } from "@reduxjs/toolkit";

import apiSlice from "./api/apiSlice";
import msApiSlice from "./api/msApiSlice";
import analyticsApiSlice from "./api/analyticsApiSlice";
import authReducer from "../features/auth/authSlice";
import userReducer from "../features/user/userSlice";
import vendorReducer from "../features/parameters/vendors/vendorSlice";
import tagsReducer from "../features/parameters/tagsManager/tagsManagerSlice";
import categoryReducer from "../features/parameters/categories/categorySlice";
import collectionReducer from "../features/parameters/collections/collectionSlice";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [msApiSlice.reducerPath]: msApiSlice.reducer,
    [analyticsApiSlice.reducerPath]: analyticsApiSlice.reducer,
    auth: authReducer,
    user: userReducer,
    vendor: vendorReducer,
    tags: tagsReducer,
    category: categoryReducer,
    collection: collectionReducer,
    // vendor: vendorReducer,
    // tags: tagsReducer,
    // category: categoryReducer,
    // collection: collectionReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      apiSlice.middleware,
      msApiSlice.middleware,
      analyticsApiSlice.middleware
    );
  },
  devTools: true,
  // devTools: process.env.REACT_APP_ENV !== "dev" ? false : true,
});

export default store;
