const PCJWeb = () => {
  return (
    <>
      <div className="d-flex justify-content-center py-1">
        <div className="device-gradient" style={{ width: "8px" }}></div>
        <div className="device-gradient-2 mx-2" style={{ width: "80px" }}></div>
        <div className="device-gradient" style={{ width: "8px" }}></div>
      </div>
      <div className="border-grey-8 bg-black-20 h-100">
        <iframe
          src="https://kisna-frontend.vercel.app/"
          className="iFrame w-100 h-100"
        />
      </div>
    </>
  );
};

export default PCJWeb;
