import React, { useMemo, useReducer, useState } from "react";
import { Link } from "react-router-dom";
// ! COMPONENT IMPORTS
import AllTransactionTable from "./AllTransactionTable";
import TabPanel from "../../../components/TabPanel/TabPanel";
import ViewTutorial from "../../../components/ViewTutorial/ViewTutorial";
import ViewLogsDrawer from "../../../components/ViewLogsDrawer/ViewLogsDrawer";
import ImportSecondDialog from "../../../components/ImportSecondDialog/ImportSecondDialog";
import ExportDialog from "../../../components/ExportDialog/ExportDialog";
import TableSearch, { TableSearchSecondary } from "../../../components/TableSearch/TableSearch";
import FilterOrders from "../../../components/FilterOrders/FilterOrders";
import OrderSortFilter from "../OrderSortFilter";
import OrderColumnsFilter from "../OrderColumnsFilter";
import OrderTagsFilter from "../OrderTagsFilter";
import OrderStatusFilter from "../OrderStatusFilter";
import OrderLocationFilter from "../OrderLocationFilter";
// ! IMAGES IMPORTS
import indiaFlag from "../../../assets/images/products/indiaFlag.svg";
import allFlag from "../../../assets/images/products/allFlag.svg";
import usaFlag from "../../../assets/images/products/usaFlag.svg";
import ukFlag from "../../../assets/images/products/ukFlag.svg";
import arrowDown from "../../../assets/icons/arrowDown.svg";
import analyticsUp from "../../../assets/icons/analyticsUp.svg";
import analyticsDown from "../../../assets/icons/analyticsDown.svg";
import orders from "../../../assets/icons/sidenav/orders.svg";
// ! MATERIAL IMPORTS
import { Box, Paper, Popover, Tab, Tabs } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useGetAllTransactionsQuery } from "../../../features/transactions/transactionsApiSlice";
import TransactionSortFilter from "./TransactionSortFilter";
import TransactionStatusFilter from "./TransactionStatusFilter";
import ExportDialogTransaction from "../../../components/ExportDialog/ExportDialogTransaction";

const initialQueries = {
  search: "",
  paymentStatus: [],
  pageNo: 1,
  pageSize: 10,
  tabIndex: 0,
  amount: "",
  createdAt: "-1",
};

const queriesReducer = (state, action) => {
  switch (action.type) {
    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageSize: +action.pageSize,
        pageNo: initialQueries.pageNo,
      };
    case "SET_PAGE_NO":
      return {
        ...state,
        pageNo: +action.pageNo,
      };
    case "SET_SEARCH":
      return {
        ...state,
        search: action.search,
        pageNo: initialQueries.pageNo,
      };
    case "SET_SORTING":
      const { amount = "", createdAt = "" } = action;
      return {
        ...state,
        amount: amount,
        createdAt: createdAt,
        pageNo: initialQueries.pageNo,
      };
    case "SET_STATUS":
      return {
        ...state,
        paymentStatus: action.status,
        pageNo: initialQueries.pageNo,
      };
    case "SET_TAB_INDEX":
      return {
        ...action.state,
        tabIndex: action.tabIndex,
        search: initialQueries.search,
        paymentStatus: initialQueries.status,
        pageNo: initialQueries.pageNo,
        pageSize: initialQueries.pageSize,
      };
    case "SET_ALL_FILTERS":
      return {
        ...initialQueries,
        ...action.filter,
      };
    default:
      return initialQueries;
  }
};

const AllOrders = () => {
  const [searchKey, setSearchKey] = useState("");
  const [queryFilters, dispatchQueryFilters] = useReducer(queriesReducer, initialQueries);

  // ? POPOVERS STARTS HERE

  // * FLAG POPOVERS STARTS
  const [anchorFlagEl, setAnchorFlagEl] = React.useState(null);
  const handleFlagClick = (event) => {
    setAnchorFlagEl(event.currentTarget);
  };
  const handleFlagClose = () => {
    setAnchorFlagEl(null);
  };
  const openFlag = Boolean(anchorFlagEl);
  const idFlag = openFlag ? "simple-popover" : undefined;
  // * FLAG POPOVERS ENDS

  // * DAYS POPOVERS STARTS
  const [anchorDaysEl, setDaysEl] = React.useState(null);

  const handleDaysClick = (event) => {
    setDaysEl(event.currentTarget);
  };

  const handleDaysClose = () => {
    setDaysEl(null);
  };

  const openDays = Boolean(anchorDaysEl);
  const idDays = openDays ? "simple-popover" : undefined;
  // * DAYS POPOVERS ENDS

  // ? POPOVERS ENDS HERE

  const onChangeRowsPerPage = (event) =>
    dispatchQueryFilters({
      type: "SET_PAGE_SIZE",
      pageSize: event.target.value,
    });
  const onChangePage = (_, pageNo) =>
    dispatchQueryFilters({ type: "SET_PAGE_NO", pageNo: pageNo + 1 });

  const { data: allTransactionsData, isLoading: allTransactionsIsLoading } =
    useGetAllTransactionsQuery(queryFilters);

  const [allTransactions, totalTransactions] = useMemo(() => {
    const { data = [], totalCount = 0 } = allTransactionsData?.data ?? {};
    return [data, totalCount];
  }, [allTransactionsData]);

  return (
    <div className="container-fluid page">
      <div className="row justify-content-between align-items-center">
        <h4 className="page-heading w-auto ps-0">All Transactions</h4>
        <div className="d-flex align-items-center w-auto pe-0">
          {/* <ViewTutorial /> */}
          <ViewLogsDrawer
            headingName={"Orders Module"}
            icon={orders}
            module="order"
          />
          <ExportDialogTransaction
            dialogName={"Orders"}
            totalCount={totalTransactions}
            currentPage={queryFilters?.pageNo}
          />
          {/* <ExportDialog dialogName={"Orders"} /> */}
        </div>
      </div>

      {/* <div className="row mt-2">
        <div className="col-md-3 col-6 ps-0 my-3 d-flex">
          <div className="border-grey-5 bg-black-15 rounded-8 py-3 px-3 flex-grow-1">
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex flex-column">
                <h2 className="text-lightBlue fw-400">₹3.58Cr</h2>
                <small className="text-grey-6 mt-2">Payment Volume</small>
              </div>
              <div className="d-flex flex-column align-items-end">
                <img
                  src={analyticsUp}
                  alt="analyticsUp"
                  className=""
                  width={25}
                />
                <small className="text-green-2 mt-3">+10.78 %</small>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-6 ps-0 my-3 d-flex">
          <div className="border-grey-5 bg-black-15 rounded-8 py-3 px-3 flex-grow-1">
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex flex-column">
                <h2 className="text-lightBlue fw-400">2,309</h2>
                <small className="text-grey-6 mt-2">No of transaction</small>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-6 ps-0 my-3 d-flex">
          <div className="border-grey-5 bg-black-15 rounded-8 py-3 px-3 flex-grow-1">
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex flex-column">
                <h2 className="text-lightBlue fw-400">30%</h2>
                <small className="text-grey-6 mt-2">UPI Payments</small>
              </div>
              <div className="d-flex flex-column align-items-end">
                <img
                  src={analyticsUp}
                  alt="analyticsUp"
                  className=""
                  width={25}
                />
                <small className="text-green-2 mt-3">+10.78 %</small>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-6 ps-0 pe-0 my-3 d-flex">
          <div
            className="border-grey-5 bg-black-15 rounded-8 py-3 px-3 text-center flex-grow-1 d-flex align-items-center justify-content-center c-pointer"
            onClick={handleDaysClick}>
            <h3 className="text-lightBlue">30 Days</h3>

            <KeyboardArrowDownIcon
              sx={{
                fontSize: 30,
                marginLeft: 1,
                color: "#c8d8ff",
                cursor: "pointer",
                marginTop: "2px",
              }}
            />
          </div>

          <Popover
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            id={idDays}
            open={openDays}
            anchorEl={anchorDaysEl}
            onClose={handleDaysClose}>
            <div className="py-2 px-1">
              <small className="text-lightBlue rounded-3 p-2 hover-back d-block">
                Last 7 Days
              </small>
              <small className="text-lightBlue rounded-3 p-2 hover-back d-block">
                Last 15 Days
              </small>
              <small className="text-lightBlue rounded-3 p-2 hover-back d-block">
                Last 30 Days
              </small>
              <small className="text-blue-gradient rounded-3 p-2 d-block c-pointer">
                Custom Date
              </small>
            </div>
          </Popover>
        </div>
      </div> */}

      <div className="row">
        <Paper
          sx={{ width: "100%", mb: 0, mt: 0, p: 0 }}
          className="border-grey-5 bg-black-15">
          <Box
            sx={{ width: "100%" }}
            className="d-flex justify-content-between tabs-header-box">
            <Tabs
              value={queryFilters.tabIndex}
              onChange={(_, val) =>
                dispatchQueryFilters({ type: "SET_TAB_INDEX", tabIndex: val })
              }
              aria-label="scrollable force tabs example"
              className="tabs">
              <Tab
                label="All"
                className="tabs-head"
              />
              {/* <Tab
                label="New"
                className="tabs-head"
              />
              <Tab
                label="Complete"
                className="tabs-head"
              />
              <Tab
                label="Today"
                className="tabs-head"
              /> */}
            </Tabs>
            <div
              className="tabs-country c-pointer"
              aria-describedby={idFlag}
              variant="contained"
              onClick={handleFlagClick}>
              <img
                src={indiaFlag}
                alt="indiaFlag"
                height={15}
              />
              <p className="mx-2 text-lightBlue">India</p>
              <img
                src={arrowDown}
                alt="arrowDown"
              />
            </div>
            <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              id={idFlag}
              open={openFlag}
              anchorEl={anchorFlagEl}
              onClose={handleFlagClose}>
              <div className="px-1 py-2">
                <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
                  <img
                    src={allFlag}
                    alt="allFlag"
                    height={20}
                  />
                  <p className="ms-2 text-lightBlue">All</p>
                </div>
                <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
                  <img
                    src={ukFlag}
                    alt="usaFlag"
                    height={15}
                  />
                  <p className="ms-2 text-lightBlue">UK</p>
                </div>
                <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
                  <img
                    src={usaFlag}
                    alt="usaFlag"
                    height={15}
                  />
                  <p className="ms-2 text-lightBlue">USA</p>
                </div>
              </div>
            </Popover>
          </Box>
          <div className="d-flex align-items-center mt-3 mb-3 px-2 justify-content-between">
            <TableSearchSecondary
              value={searchKey}
              onChange={(val) => dispatchQueryFilters({ type: "SET_SEARCH", search: val })}
              onSearchValueChange={(val) => setSearchKey(val)}
            />
            <div className="d-flex ms-2">
              <div className="d-flex product-button__box">
                <TransactionStatusFilter
                  list={queryFilters.paymentStatus}
                  onChange={(val) => dispatchQueryFilters({ type: "SET_STATUS", status: val })}
                />
              </div>
              <TransactionSortFilter
                queryFilters={queryFilters}
                onSortChange={(change) =>
                  dispatchQueryFilters({ type: "SET_SORTING", ...change })
                }
              />
            </div>
          </div>
          <TabPanel
            value={queryFilters.tabIndex}
            index={0}>
            <AllTransactionTable
              pageNo={queryFilters.pageNo}
              pageSize={queryFilters.pageSize}
              isLoading={allTransactionsIsLoading}
              allTransactions={allTransactions}
              totalTransactions={totalTransactions}
              changePage={onChangePage}
              changeRowsPerPage={onChangeRowsPerPage}
            />
          </TabPanel>
          {/* <TabPanel
            value={value}
            index={1}>
            <AllOrdersTable />
          </TabPanel>
          <TabPanel
            value={value}
            index={2}>
            <AllOrdersTable />
          </TabPanel>
          <TabPanel
            value={value}
            index={3}>
            <AllOrdersTable />
          </TabPanel> */}
        </Paper>
      </div>
    </div>
  );
};

export default AllOrders;
