import { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Popover,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useDeleteCustomerAddressMutation } from "../../../../features/customers/customerAddress/customerAddressApiSlice";

import UserActivityTable from "./UserActivityTable/UserActivityTable";
import UserIPTable from "./UserIPTable/UserIPTable";
import UserWishlistTable from "./UserWishlistTable/UserWishlistTable";
import AddAddress from "../AddAddress";
import { DeleteModalSecondary } from "../../../../components/DeleteModal/DeleteModal";
// import TabPanel from "../../../../components/TabPanel/TabPanel";

import AppCustomerOverviewChart from "../../../../components/AppCustomerOverviewChart/AppCustomerOverviewChart";
import AddNotesDialog from "../../../../components/AddNotesDialog/AddNotesDialog";

import archivedGrey from "../../../../assets/icons/archivedGrey.svg";
import editGrey from "../../../../assets/icons/editGrey.svg";
import location from "../../../../assets/icons/location.svg";
import activity from "../../../../assets/icons/activity.svg";
import chart from "../../../../assets/icons/chart.svg";
import refresh from "../../../../assets/icons/refresh.svg";
import NoDataFound from "../../../../components/NoDataFound/NoDataFound";

const UserInformation = ({
  addresses,
  addressId,
  customerId = "",
  totalOrder = 0,
  totalAmount = 0,
  avgPrice = 0,
  openCustomerNote = () => {},
}) => {
  const [activityDateValue, setActivityDateValue] = useState(new Date());
  const [anchorActivityEl, setAnchorActivityEl] = useState(null);
  const [openUser, showOpenUser] = useState(false);
  const [address, setAddressData] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [addressData, setAddressId] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [noteFilterType, setNoteFilterType] = useState("");
  const newIndex = tabIndex === 0 ? "shipping" : "billing";
  const addressType = addresses?.filter((address) => address?.type === newIndex);

  const handleTabChange = (_, index) => {
    setTabIndex(index);
  };
  const handleUserClose = () => {
    showOpenUser(false);
  };

  const hideAddress = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteModal = () => {
    setShowDeleteModal(false);
    deleteCustomerAddress(addressData?._id);
  };

  const handleEditAddress = (address) => {
    showOpenUser(true);
    setAddressData(address);
  };

  const [deleteCustomerAddress] = useDeleteCustomerAddressMutation();

  const handleActivityDateChange = (newValue) => {
    setActivityDateValue(newValue);
  };

  const handleActivityClick = (event) => {
    setAnchorActivityEl(event.currentTarget);
  };
  const handleActivityClose = () => {
    setAnchorActivityEl(null);
  };
  const openActivity = Boolean(anchorActivityEl);
  const idActivity = openActivity ? "simple-popover" : undefined;

  const deleteAddr = (addrId) => {
    setAddressId(addrId);
    setShowDeleteModal(true);
  };

  return (
    <>
      <div className="bg-black-15 border-grey-5 rounded-8 row ">
        <div className="col-12 d-flex mt-3 justify-content-between px-3">
          <div className="d-flex align-items-center">
            <img
              src={chart}
              alt="location"
            />
            <h6 className="text-lightBlue ms-2 fw-500">Customer Overview</h6>
          </div>
          <div className="d-flex align-items-center">
            <small className="text-lightBlue me-1">
              Last Update:&nbsp;<span className="fw-500">April 25,2022</span>
            </small>
            <img
              src={refresh}
              alt="refresh"
              width={16}
            />
          </div>
        </div>
        <div className="mt-3 px-3">
          <hr className="hr-grey-6 m-0" />
        </div>
        <div className="col-12 px-0">
          <div className="row p-3 mx-0">
            <div
              className="col-md-8 col-lg-9 d-flex justify-content-between align-items-center mb-3 mb-md-0 rounded-8 px-0"
              style={{ background: "rgba(39, 40, 63, 0.5)" }}>
              <AppCustomerOverviewChart />
            </div>
            <div className="col-lg-3 col-md-4 d-flex flex-md-column flex-row">
              <div
                className="row mb-md-3 rounded-8 ms-md-0 py-2"
                style={{ background: "rgba(39, 40, 63, 0.5)" }}>
                <div className="col-12 d-flex justify-content-between">
                  <p className="text-blue-gradient">Total Orders</p>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <InfoOutlinedIcon
                      sx={{
                        color: "#c8d8ff",
                        fontSize: 18,
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="col-12 mt-2">
                  <h6 className="text-lightBlue fw-500">{totalOrder}</h6>
                </div>
                {/* <div className="col-12">
                  <small className="text-grey-6">
                    <span className="text-decoration-underline text-blue-2">Last Order:</span>
                    &nbsp;April 20, 2022
                  </small>
                </div> */}
              </div>
              <div
                className="row mb-md-3 rounded-8 ms-md-0 py-2"
                style={{ background: "rgba(39, 40, 63, 0.5)" }}>
                <div className="col-12 d-flex justify-content-between">
                  <p className="text-blue-gradient">Lifetime Spent</p>
                  <Tooltip
                    title="info"
                    placement="top">
                    <InfoOutlinedIcon
                      sx={{
                        color: "#c8d8ff",
                        fontSize: 18,
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="col-12 mt-2">
                  <h6 className="text-lightBlue fw-500">₹ {totalAmount}</h6>
                </div>
                {/* <div className="col-12">
                  <small className="text-grey-6">
                    Mostly spent on&nbsp;
                    <span className="text-decoration-underline text-blue-2">Rings</span>
                  </small>
                </div> */}
              </div>
              <div
                className="row mb-md-3 rounded-8 ms-md-0 py-2"
                style={{ background: "rgba(39, 40, 63, 0.5)" }}>
                <div className="col-12 d-flex justify-content-between">
                  <p className="text-blue-gradient">Average Order Value</p>
                  <Tooltip
                    title="info"
                    placement="top">
                    <InfoOutlinedIcon
                      sx={{
                        color: "#c8d8ff",
                        fontSize: 18,
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="col-12 mt-2">
                  <h6 className="text-lightBlue fw-500">₹ {avgPrice}</h6>
                </div>
                {/* <div className="col-12">
                  <small className="text-grey-6">
                    Mostly spent on&nbsp;
                    <span className="text-decoration-underline text-blue-2">Rings</span>
                  </small>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black-15 border-grey-5 mt-4 rounded-8 row">
        <div className="col-12 d-flex mt-3 px-3">
          <img
            src={location}
            alt="location"
          />
          <h6 className="text-lightBlue ms-2 fw-500">Address</h6>
        </div>
        <div className="my-3 px-3">
          <hr className="hr-grey-6 m-0" />
        </div>

        <div>
          <Box
            sx={{ width: "100%" }}
            className="d-flex justify-content-between tabs-header-box mb-3">
            <Tabs
              className="tabs"
              value={tabIndex}
              onChange={handleTabChange}>
              <Tab
                label="Shipping Address"
                className="tabs-head"
              />
              <Tab
                label="Billing Address"
                className="tabs-head"
              />
            </Tabs>
          </Box>

          <div style={{ height: 350, overflowY: "auto" }}>
            {!addressType?.length && <NoDataFound />}
            {addressType?.map((address, index) => (
              <div
                key={index}
                className="col-12 px-3">
                <div
                  className="row py-3 mb-3 rounded-8 mx-0"
                  style={{ background: "rgba(39, 40, 63, 0.5)" }}>
                  <div className="col-12 d-flex justify-content-between align-items-center mb-2 px-3">
                    <p className="text-lightBlue">{address?.name}</p>
                    <div className="d-flex align-items-center">
                      {/* {address?.isDefaultAddress &&<Chip label="Default" size="small" className="px-2" />} */}
                      <Tooltip
                        title="Edit"
                        placement="top">
                        <div
                          className="table-edit-icon rounded-4 p-1"
                          onClick={() => handleEditAddress(address)}>
                          <img
                            src={editGrey}
                            alt="editGrey"
                            className="c-pointer mx-1"
                            width={16}
                          />
                        </div>
                      </Tooltip>
                      <Tooltip
                        title="Delete"
                        placement="top">
                        <div
                          className="table-edit-icon rounded-4 p-1"
                          onClick={() => deleteAddr(address)}>
                          <img
                            src={archivedGrey}
                            alt="archiverdGrey"
                            className="c-pointer mx-1"
                            width={16}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-12 px-3">
                    <small className="text-lightBlue d-block">
                      {address?.firstName} {address?.lastName}
                    </small>
                    <small className="text-lightBlue d-block">{address?.line1}</small>
                    <small className="text-lightBlue d-block">{address?.companyName}</small>
                    <small className="text-lightBlue d-block">
                      {address?.city?.name}-{address?.pinCode},{address?.state?.name},
                      {address?.country?.name}
                    </small>
                    <small className="text-lightBlue d-block">
                      {address?.country?.countryCode} {address?.phone}
                    </small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-black-15 border-grey-5 mt-4 rounded-8 row">
        <div className="col-12 d-flex mt-3 px-3">
          <h6 className="text-lightBlue fw-500">Wishlist</h6>
        </div>
        <div className="my-3 px-3">
          <hr className="hr-grey-6 m-0" />
        </div>
        <div className="col-12 px-0">
          <UserWishlistTable customerId={customerId} />
        </div>
      </div>
      <div className="bg-black-15 border-grey-5 mt-4 rounded-8 row  ">
        <div className="col-12 d-flex mt-3 align-items-center justify-content-between">
          <h6 className="text-lightBlue ms-2 fw-500">Activity</h6>
          <div className="d-flex">
            {console.log(noteFilterType)}
            <FormControl
              className="px-0"
              size="small">
              <Select
                displayEmpty="All"
                sx={{ minWidth: "8rem" }}
                size="small"
                name="field"
                value={noteFilterType}
                onChange={(e) => setNoteFilterType(e.target.value)}>
                <MenuItem
                  value=""
                  sx={{ fontSize: 13, color: "#5c6d8e" }}>
                  All
                </MenuItem>
                <MenuItem
                  value="wishlist"
                  sx={{ fontSize: 13, color: "#5c6d8e" }}>
                  Wishlist
                </MenuItem>
                <MenuItem
                  value="order"
                  sx={{ fontSize: 13, color: "#5c6d8e" }}>
                  Order
                </MenuItem>
                <MenuItem
                  value="appointment"
                  sx={{ fontSize: 13, color: "#5c6d8e" }}>
                  Appointment
                </MenuItem>
                <MenuItem
                  value="custom"
                  sx={{ fontSize: 13, color: "#5c6d8e" }}>
                  Custom
                </MenuItem>
                <MenuItem
                  value="others"
                  sx={{ fontSize: 13, color: "#5c6d8e" }}>
                  Others
                </MenuItem>
              </Select>
            </FormControl>

            <button
              className="button-gradient py-2 px-3 ms-2"
              onClick={openCustomerNote}>
              <small>+ Add Notes</small>
            </button>
          </div>
        </div>
        <div className="my-3 px-3">
          <hr className="hr-grey-6 m-0" />
        </div>
        <div className="col-12 px-0">
          <UserActivityTable
            customerId={customerId}
            type={noteFilterType}
          />
        </div>
      </div>
      <AddAddress
        addresses={address}
        openUser={openUser}
        handleUserClose={handleUserClose}
        addressId={addressId}
      />
      <DeleteModalSecondary
        message={addressData?.name}
        title="customer address"
        onConfirm={handleDeleteModal}
        onCancel={hideAddress}
        show={showDeleteModal}
      />
    </>
  );
};

export default UserInformation;

/* <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDateTimePicker
                value={activityDateValue}
                onChange={(newValue) => {
                  handleActivityDateChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Enter Date & Time"
                  />
                )}
              />
            </LocalizationProvider> */

/* <button
              className="button-grey py-2 px-3 ms-2"
              aria-describedby={idActivity}
              variant="contained"
              onClick={handleActivityClick}>
              <small className="text-lightBlue">Activity</small>
              <img
                src={activity}
                alt="activity"
                className="ms-2"
              />
            </button> */

/* <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              id={idActivity}
              open={openActivity}
              anchorEl={anchorActivityEl}
              onClose={handleActivityClose}>
              <div className="py-2 px-1">
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Viewed User
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Edited User
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Updated User Status
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Archive User
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Added Comments
                </small>
              </div>
            </Popover> */

/* <div className="bg-black-15 border-grey-5 mt-4 rounded-8 row  ">
        <div className="col-12 d-flex mt-3 justify-content-between align-items-center">
          <h6 className="text-lightBlue ms-2 fw-500">Access to Dashboard (IP)</h6>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDateTimePicker
              value={activityDateValue}
              onChange={(newValue) => {
                handleActivityDateChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Enter Date & Time"
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="my-3 px-3">
          <hr className="hr-grey-6 m-0" />
        </div>
        <div className="col-12 px-0">
          <UserIPTable />
        </div>
      </div> */
