import { forwardRef } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import cancel from "../../assets/icons/cancel.svg";
import archiveIcon from "../../assets/images/Components/Archived.png";
import { LoadingButton } from "@mui/lab";
import {
  useRefundOrderTransactionMutation,
  useUpdateOrderMutation,
} from "../../features/orders/ordersApiSlice";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../features/snackbar/snackbarAction";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function CancelRefundOrderDialog({ order = null, onClose = () => {} }) {
  const dispatch = useDispatch();
  // console.log(order);
  const closeDialog = () => onClose(null);
  const [updateOrder, { isLoading: updateOrderIsLoading }] = useUpdateOrderMutation();
  const [refundOrder, { isLoading: refundOrderIsLoading }] =
    useRefundOrderTransactionMutation();

  const dialogAction = async () => {
    try {
      await updateOrder({ id: order?._id, order: { status: "cancelled" } }).unwrap();
      if (order?.paymentStatus === "done") refundOrder({ order: order?._id }).unwrap();
      dispatch(showSuccess({ message: "Order Cancelled successfully!" }));
      closeDialog();
    } catch (error) {
      console.log(error);
      dispatch(
        showError({
          message: error?.data?.message ?? error?.message ?? "Something went wrong!",
        })
      );
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      maxWidth="sm"
      fullWidth
      open={!!order}
      onClose={closeDialog}>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h4 className="text-lightBlue fw-500">Cancel & Refund {order?.orderID}</h4>
          </div>
          <button
            className="reset"
            onClick={closeDialog}>
            <img
              src={cancel}
              alt="cancel"
              width={30}
              className="c-pointer"
            />
          </button>
        </div>
      </DialogTitle>

      <hr className="hr-grey-6 my-0" />

      <DialogContent className="pt-3 pb-0 px-4 d-flex align-items-center">
        <img
          src={archiveIcon}
          alt="icon"
          style={{ width: "80px", height: "80px" }}
        />
        <div>
          <p
            style={{ fontSize: "16px" }}
            className="text-lightBlue mx-4">
            All the items in this order will be cancelled!
          </p>
          {order?.paymentStatus === "done" && (
            <p
              style={{ fontSize: "16px" }}
              className="text-grey-6 mx-4">
              Amount of{" "}
              <span className="text-blue-2">
                ₹ {order?.transactions?.reduce((to, tx) => to + tx.amount, 0)}{" "}
              </span>
              will be refunded to original payment method.
            </p>
          )}
        </div>
      </DialogContent>

      <DialogActions className="d-flex justify-content-end px-4 py-3">
        <LoadingButton
          loading={updateOrderIsLoading || refundOrderIsLoading}
          disabled={updateOrderIsLoading || refundOrderIsLoading}
          onClick={dialogAction}
          className="button-red-outline py-2 px-4"
          style={{ lineHeight: 1.6 }}>
          <p>Yes</p>
        </LoadingButton>
        <span className="pe-2" />
        <button
          disabled={updateOrderIsLoading || refundOrderIsLoading}
          onClick={closeDialog}
          className="button-grey-outline py-2 px-4"
          style={{ lineHeight: 1.6 }}>
          <p className="text-lightBlue">No</p>
        </button>
      </DialogActions>
    </Dialog>
  );
}
