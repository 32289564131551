import React, { useCallback } from "react";
import AddProducts from "../../pages/Parameters/CreateCollection/AddProducts";
import AddManualProducts from "../AddProducts/AddManualProducts";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputBase,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  EnhancedTableHead,
  getComparator,
  stableSort,
} from "../TableDependencies/TableDependencies";
import styled from "@emotion/styled";

import deleteWhite from "../../assets/icons/deleteWhite.svg";
import editWhite from "../../assets/icons/editWhite.svg";
import deleteButton from "../../assets/icons/deleteButton.svg";
import ringSmall from "../../assets/images/ringSmall.svg";

import SearchIcon from "@mui/icons-material/Search";
import AddFilters from "../DiscountFormat/AddFilters";
import DeleteIconButton from "../DeleteIconButton/DeleteIconButton";

import info from "../../assets/icons/info.svg";
import { useDispatch } from "react-redux";
import { showError } from "../../features/snackbar/snackbarAction";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {},
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
  height: "30.6px",
  border: "1px solid #38395c",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(0.8, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    borderRadius: "5px",
  },
}));

function createLikeProductData(pId, productName, category, price) {
  return { pId, productName, category, price };
}

const likeProductRows = [
  createLikeProductData(
    1,
    "The Fringe Diamond Ring",
    "Gold Products",
    "₹ 25,000"
  ),
  createLikeProductData(2, "Fringe Diamond Ring", "Gold Products", "₹ 25,000"),
  createLikeProductData(
    3,
    "The Fringe Diamond Ring",
    "Gold Products",
    "₹ 25,000"
  ),
];

function createData(pId, productName, category, price) {
  return { pId, productName, category, price };
}
const rows = [
  createData(
    1,
    "The Fringe Diamond Ring",
    "Gold Products",
    "₹ 20,600 - ₹ 50,000"
  ),
  createData(
    2,
    "The Fringe Diamond Ring",
    "Gold Products",
    "₹ 20,600 - ₹ 50,000"
  ),
  createData(
    3,
    "The Fringe Diamond Ring",
    "Gold Products",
    "₹ 20,600 - ₹ 50,000"
  ),
  createData(
    4,
    "The Fringe Diamond Ring",
    "Gold Products",
    "₹ 20,600 - ₹ 50,000"
  ),
  createData(
    5,
    "The Fringe Diamond Ring",
    "Gold Products",
    "₹ 20,600 - ₹ 50,000"
  ),
];

const drawerHeadCells = [
  {
    id: "productName",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

const AddProductCondition = ({ formik }) => {
  // const [likeProductRadio, setLikeProductRadio] = React.useState("automated");
  // const handleLikeProductRadio = (event) => {
  //   setLikeProductRadio(event.target.value);
  // };

  const [likeMatchRadio, setLikeMatchRadio] = React.useState("allCondition");
  const handleLikeMatchRadio = (event) => {
    setLikeMatchRadio(event.target.value);
  };

  const onProductChange = useCallback(
    (products) => formik.setFieldValue("products", products),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const changeType = useCallback((e, v) => {
    formik.setFieldValue("addProductType", v);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("productName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = rows.map((n) => n.pId);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleLikeSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = likeProductRows.map((n) => n.pId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  // * TABLE ENDS HERE

  // ? SIZE SELECT STARTS HERE
  const [field, setField] = React.useState("price");

  const handleFieldChange = (event) => {
    setField(event.target.value);
  };
  // ? SIZE SELECT ENDS HERE

  // ? OPERATOR SELECT STARTS HERE
  const [operator, setOperator] = React.useState("equals");

  const handleOperatorChange = (event) => {
    setOperator(event.target.value);
  };
  // ? OPERATOR SELECT ENDS HERE

  // ? CHECKBOX STARTS HERE
  const [checked, setChecked] = React.useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };
  // ? CHECKBOX ENDS HERE

  // ? LIKE ADD CONDITION STARTS HERE
  const [likeAddCondition, setLikeAddCondition] = React.useState(false);
  const handleLikeAddCondition = () => {
    if (!likeAddCondition) {
      setLikeAddCondition(true);
    } else {
      setLikeAddCondition(false);
      setLikeApplyCondition(false);
    }
  };
  // ? LIKE ADD CONDITION ENDS HERE

  // ? LIKE APPLY CONDITION STARTS HERE
  const [likeApplyCondition, setLikeApplyCondition] = React.useState(false);
  const handleLikeApplyCondition = () => {
    if (likeApplyCondition) {
      setLikeApplyCondition(false);
    } else {
      setLikeApplyCondition(true);
      setLikeAddCondition(false);
    }
  };
  const dispatch = useDispatch();

  const addFilterHandler = () => {
    const newFilter = formik?.values?.filters.concat({
      type: "",
      operator: "",
      value: [],
      dropDownData: [],
    });
    formik.setFieldValue("filters", newFilter);
  };

  const deleteFilterHandler = ({ deleteIndex }) => {
    if (formik.values?.filters?.length === 1) {
      dispatch(showError({ message: "At least one filter is required" }));
      return;
    }
    const updatedFilter = formik?.values?.filters.filter(
      (_, index) => index !== deleteIndex
    );
    formik.setFieldValue("filters", updatedFilter);
  };
  return (
    <div className="bg-black-9 border-grey-5 rounded-8 p-3 row features mt-4">
      <div className="d-flex justify-content-between mb-2 px-0">
        <h6 className="text-lightBlue me-auto text-lightBlue col-auto ps-0 fw-500">
          Add Products
        </h6>
      </div>
      <div className="d-flex align-items-center col-12 px-0 mb-2">
        <p className="text-grey-6 me-4 px-0">Select Products Through</p>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={formik.values.addProductType}
          onChange={changeType}
          className="features-radio px-0"
        >
          <FormControlLabel
            value="automated"
            control={<Radio size="small" />}
            label="Automated"
            sx={{
              "& .MuiTypography-root": {
                fontSize: 13,
                color: "#c8d8ff",
                marginRight: 1,
              },
            }}
          />
          <FormControlLabel
            value="manual"
            control={<Radio size="small" />}
            label="Manual"
            sx={{
              "& .MuiTypography-root": {
                fontSize: 13,
                color: "#c8d8ff",
                marginRight: 1,
              },
            }}
          />
        </RadioGroup>
      </div>
      {formik.values.addProductType === "manual" && (
        <AddManualProducts
          productIds={formik.values.products}
          onProductsChange={onProductChange}
          showStatusColumn={false}
          // formik={formik}
          // productsList={formik.values.products}
          // field={"products"}
          // id={"products"}
          // typeValue={formik.values.addProductType}
          // typeField={"addProductType"}
        />
      )}
      {formik.values.addProductType !== "manual" && (
        <Filters
          value={formik.values?.filters}
          field="filters"
          formik={formik}
          touched={formik?.touched?.filters}
          error={formik?.errors?.filters}
          data={formik.values?.filters}
          onSort={() => {}}
          onDeleteField={deleteFilterHandler}
          onAdd={addFilterHandler}
        />
      )}
      {/* {formik.values.addProductType !== "manual" && (
        <div className="bg-black-11 rounded-8 p-3 shadow-sm">
          {formik.values.addProductType === "automated" && (
            <React.Fragment>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <p className="text-lightBlue me-4">Should Match:</p>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={likeMatchRadio}
                    onChange={handleLikeMatchRadio}
                    className="features-radio"
                  >
                    <FormControlLabel
                      value="allCondition"
                      control={<Radio size="small" />}
                      label="All Condition"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: 13,
                          color: "#c8d8ff",
                        },
                      }}
                    />
                    <FormControlLabel
                      value="anyCondition"
                      control={<Radio size="small" />}
                      label="Any Condition"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: 13,
                          color: "#c8d8ff",
                        },
                      }}
                    />
                  </RadioGroup>
                </div>
                <button
                  type="button"
                  className="button-gradient py-1 px-4"
                  onClick={handleLikeAddCondition}
                >
                  <p>Add Condition</p>
                </button>
              </div>
              <div className="bg-black-9 align-items-center rounded-8 py-2 px-3 d-flex justify-content-between mt-3 shadow-lg">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                      size="small"
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                        width: "auto",
                      }}
                    />
                  }
                  label="Summary"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "0.875rem",
                      color: "#c8d8ff",
                    },
                  }}
                  className=" px-0"
                />
                <p className="text-lightBlue c-pointer">Action</p>
              </div>
              {likeApplyCondition && (
                <div className="d-flex px-3 justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleCheckboxChange}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                          style={{
                            color: "#5C6D8E",
                            marginRight: 0,
                            width: "auto",
                          }}
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.875rem",
                          color: "#c8d8ff",
                        },
                      }}
                      className="px-0 me-0"
                    />
                    <small className="ms-0 text-lightBlue">
                      <span className="text-blue-2">Price</span>&nbsp;is equal
                      to&nbsp;
                      <span className="text-blue-2">₹&nbsp;25,000</span>
                    </small>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src={editWhite}
                      alt="editWhite"
                      width={30}
                      className="me-1"
                    />
                    <img src={deleteWhite} alt="deleteWhite" width={30} />
                  </div>
                </div>
              )}
              {likeAddCondition && (
                <div className="row">
                  <div className="col-sm-6 col-md-3 mt-3 mb-1 ps-4">
                    <p className="text-lightBlue mb-1">Field</p>

                    <FormControl className="w-100 px-0" size="small">
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={field}
                        onChange={handleFieldChange}
                        size="small"
                      >
                        <MenuItem
                          value="price"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Price
                        </MenuItem>
                        <MenuItem
                          value={"collection"}
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Collection
                        </MenuItem>
                        <MenuItem
                          value={"tags"}
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Tags
                        </MenuItem>
                        <MenuItem
                          value={"category"}
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Catagory
                        </MenuItem>
                        <MenuItem
                          value={"subCategory"}
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Sub Category
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-sm-6 col-md-3 mt-3 mb-1">
                    <p className="text-lightBlue mb-1">Operator</p>

                    <FormControl className="w-100 px-0" size="small">
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={operator}
                        onChange={handleOperatorChange}
                        size="small"
                      >
                        <MenuItem
                          value="equals"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Equals
                        </MenuItem>
                        <MenuItem
                          value={"notEquals"}
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Not Equals
                        </MenuItem>
                        <MenuItem
                          value={"greaterThan"}
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Greater Than
                        </MenuItem>
                        <MenuItem
                          value={"less"}
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Less
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-sm-6 col-md-3 mt-3 mb-1">
                    <p className="text-lightBlue mb-1">Value</p>

                    <FormControl className="w-100 px-0">
                      <OutlinedInput
                        placeholder="Enter Value"
                        size="small"
                        defaultValue="25000"
                        startAdornment={
                          <InputAdornment position="start">
                            <p className="text-lightBlue">₹</p>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="col-sm-6 col-md-3 mt-3 mb-1">
                    <button
                      type="button"
                      className="button-gradient py-1 px-3 w-100 mb-2"
                      onClick={handleLikeApplyCondition}
                    >
                      <p>Apply</p>
                    </button>
                    <button
                      type="button"
                      className="button-lightBlue-outline py-1 px-3 w-100"
                      onClick={handleLikeApplyCondition}
                    >
                      <p>Cancel</p>
                    </button>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      )}
      {formik.values.addProductType === "automated" && likeApplyCondition && (
        <React.Fragment>
          <div className="col-12 mt-3">
            <div className="row align-items-center">
              <div className="col-md-9 px-md-0 py-2">
                <Search className="mx-0">
                  <SearchIconWrapper>
                    <SearchIcon sx={{ color: "#c8d8ff" }} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </div>
              <div className="col-md-3 pe-md-0 py-2">
                <button
                  type="button"
                  className="button-gradient w-100 py-1 px-3"
                  // onClick={toggleAddProductDrawer("right", true)}
                >
                  <p>Add Products</p>
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 px-0">
            <TableContainer className="mt-3">
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleLikeSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={likeProductRows.length}
                  headCells={drawerHeadCells}
                />
                <TableBody>
                  {stableSort(likeProductRows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.pId);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.pId}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              size="small"
                              onClick={(event) => handleClick(event, row.pId)}
                              style={{
                                color: "#5C6D8E",
                                marginRight: 0,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <div className="d-flex align-items-center my-2">
                              <img
                                src={ringSmall}
                                alt="ringSmall"
                                className="me-2"
                                height={45}
                                width={45}
                              />
                              <div>
                                <p className="text-lightBlue fw-600">
                                  {row.productName}
                                </p>
                                <small className="mt-2 text-grey-6">
                                  SKU: TFDR012345
                                </small>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <p className="text-lightBlue">{row.category}</p>
                          </TableCell>
                          <TableCell>
                            <div className="d-flex align-items-center c-pointer ">
                              <p className="text-lightBlue">{row.price}</p>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="d-flex align-items-center c-pointer ">
                              <img
                                src={deleteButton}
                                alt="deleteButton"
                                width={75}
                                className="c-pointer"
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="table-pagination"
            />
          </div>
        </React.Fragment>
      )} */}
    </div>
  );
};

function Filters({
  value,
  field,
  formik,
  touched,
  error,
  onAdd,
  data,
  onDeleteField,
}) {
  const filterValues = data.map((filter, index) => filter);

  return (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes mt-4 mx-auto">
      <div className="d-flex col-12 px-0 justify-content-between">
        <div className="d-flex align-items-center">
          <h6 className="text-lightBlue me-auto text-lightBlue fw-500">
            Applicable On
          </h6>
          <span style={{ color: "#F67476", paddingLeft: 4 }}>*</span>
          <Tooltip title="Lorem ipsum" placement="top">
            <img
              src={info}
              alt="info"
              className="ms-2 c-pointer"
              width={13.5}
            />
          </Tooltip>
        </div>
      </div>
      <hr className="hr-grey-6 mt-3 mb-0" />
      <div className="col-12 px-0">
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="medium">
            {/* <TableHeader headCells={HEAD_CELLS} /> */}
            <TableBody>
              <div className="row mt-3 mb-0 mx-0">
                <div className="col-md-3 mt-1 ps-0">
                  <div className="d-flex mb-1">
                    <p className="text-lightBlue">Applies to</p>
                    <Tooltip title="Lorem ipsum" placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="ms-2 c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                </div>
                {formik?.values?.filters[0]?.type === "allProducts" &&
                formik?.values?.filters.length === 1 ? null : (
                  <>
                    <div className="col-md-3 mt-1">
                      <div className="d-flex mb-1">
                        <p className="text-lightBlue">Condition</p>
                        <Tooltip title="Lorem ipsum" placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="ms-2 c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="col-md-5 mt-1">
                      <div className="d-flex mb-1">
                        <p className="text-lightBlue">Select</p>
                        <Tooltip title="Lorem ipsum" placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="ms-2 c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {data?.map((item, index) => {
                return (
                  <TableRow className="table-rows" key={index}>
                    <TableCell
                      colSpan={3}
                      className="pt-0"
                      style={{ paddingLeft: 12 }}
                    >
                      <AddFilters
                        index={index}
                        formik={formik}
                        value={formik?.values?.filters[index]}
                        field={`filters[${index}]`}
                        touched={
                          formik?.touched?.filters?.length &&
                          formik?.touched?.filters[index]
                        }
                        error={
                          formik?.errors?.filters?.length &&
                          formik?.errors?.filters[index]
                        }
                      />
                    </TableCell>
                    {data.length > 1 && (
                      <TableCell
                        style={{ width: 16, paddingTop: 0, paddingRight: 0 }}
                      >
                        <div className="d-flex ">
                          <DeleteIconButton
                            onClick={onDeleteField.bind(null, {
                              deleteIndex: index,
                            })}
                            title="Delete"
                          />
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="row">
          <div className="col-12">
            <small
              className="text-blue-2 c-pointer fw-500"
              onClick={onAdd}
              type="button"
            >
              + Add More Filter
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProductCondition;
