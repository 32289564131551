import { Fragment, useState } from "react";
// ! COMPONENT IMPORTS
// ! IMAGES IMPORTS
import product2Icon from "../../../assets/images/products/product2.jpg";
// ! MATERIAL IMPORTS
import { Popover, Chip } from "@mui/material";
// ! MATERIAL ICONS IMPORTS
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import editWhiteIcon from "../../../assets/icons/editWhite.svg";
import deleteWhiteIcon from "../../../assets/icons/deleteWhite.svg";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";
import { PriceBreakup } from "../../Products/AddProduct/Variants/Variants";

const OrderProductCard = ({ products = [], removeProduct = () => {} }) => {
  return (
    <>
      {products.map((pv, ix) => {
        const { product = {}, variant = {}, inventory = {} } = pv;
        // console.log(inventory);
        return (
          <div
            key={ix}
            className="bg-black-21 rounded-8 p-3 col-12 mt-3">
            <div className="d-flex align-items-center justify-content-between ">
              <div className="d-flex align-items-center">
                <p className="text-grey-6 w-auto pe-0">Location:</p>

                <Chip
                  label={inventory?.store?.name ?? ""}
                  size="small"
                  className="px-1 w-auto ms-2"
                  variant="outlined"
                />
              </div>
              <div className="d-flex align-items-center">
                {/* <img
                  width={30}
                  alt="editWhite"
                  src={editWhiteIcon}
                  className="mx-1 c-pointer"
                /> */}
                <img
                  width={30}
                  alt="deleteWhite"
                  src={deleteWhiteIcon}
                  className="mx-1 c-pointer"
                  onClick={() => removeProduct(ix)}
                />
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-5 mt-3">
                <div className="d-flex align-items-start">
                  <AsyncImage
                    src={product?.mediaUrl?.find((mu) => mu.isDefault)?.image ?? ""}
                    placeholder={product2Icon}
                    alt="product"
                    className="me-2 rounded-4"
                    height={80}
                    width={80}
                  />
                  <div className="d-flex flex-column ms-3">
                    <p className="text-lightBlue fw-500">{product?.title}</p>
                    <small className="text-grey-6 mt-1 d-block">
                      SKU: {product?.inventory?.skuId}
                    </small>
                    <small className="text-grey-6 mt-1 d-block">Brand: Kisna Jewellers</small>
                    {!!variant && (
                      <div className="d-flex mt-2 align-items-center mt-2">
                        <VariantInfoPopOver variant={variant} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-4 d-flex flex-column justify-content-between mt-3 align-items-end">
                <div className="d-flex">
                  <small className="text-lightBlue me-3">Calculated Price: ₹</small>
                  {!product?.price?.dynamicPricing ? (
                    <h6
                      className="text-lightBlue me-2 fw-500"
                      variant="contained">
                      {product?.price?.salePrice}
                    </h6>
                  ) : (
                    <PriceBreakup variant={variant} />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OrderProductCard;

export function VariantInfoPopOver({ variant }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const isOpen = Boolean(anchorEl);

  // console.log("variant", variant);

  const sizeAttribute = variant.attributes.find((a) => a.title === "size");
  const metalAttribute = variant.attributes.find((a) => a.title === "metal");
  const diamondAttribute = variant.attributes.find((a) => a.title === "diamond");
  const gemstoneAttribute = variant.attributes.find((a) => a.title === "gemstone");
  const pearlAttribute = variant.attributes.find((a) => a.title === "pearl");

  const sizeOptions = !!sizeAttribute
    ? variant.options.filter((o) => o.metaAttribute.data.attribute === sizeAttribute._id)
    : [];

  const metalOptions = !!metalAttribute
    ? variant.options.filter((o) => o.metaAttribute.data.attribute === metalAttribute._id)
    : [];

  const diamondOptions = !!diamondAttribute
    ? variant.options.filter((o) => o.metaAttribute.data.attribute === diamondAttribute._id)
    : [];

  const gemstoneOptions = !!gemstoneAttribute
    ? variant.options.filter((o) => o.metaAttribute.data.attribute === gemstoneAttribute._id)
    : [];

  const pearlOptions = !!pearlAttribute
    ? variant.options.filter((o) => o.metaAttribute.data.attribute === pearlAttribute._id)
    : [];

  const metalWeightObj = !!metalAttribute
    ? variant.priceBreakup.find((pb) => pb.attribute === metalAttribute._id)
    : undefined;

  const diamondWeightObj = !!diamondAttribute
    ? variant.globalPriceManager.find((pb) => pb.attribute === diamondAttribute._id)
    : undefined;

  const gemstoneWeightObj = !!gemstoneAttribute
    ? variant.globalPriceManager.find((pb) => pb.attribute === gemstoneAttribute._id)
    : undefined;

  const pearlWeightObj = !!pearlAttribute
    ? variant.globalPriceManager.find((pb) => pb.attribute === pearlAttribute._id)
    : undefined;

  return (
    <>
      <small
        className="text-blue-2 d-block me-2 c-pointer"
        variant="contained"
        onClick={handleOpen}>
        {[
          ...sizeOptions.map((op) => op.metaAttribute.data.title),
          ...metalOptions.flatMap((op) => [
            op.metaAttribute.data.title,
            op.metaAttribute.metaSubAttribute2.metaSubAttributeValue.title,
            op.metaAttribute.metaSubAttribute1.metaSubAttributeValue.title,
          ]),
          ...diamondOptions.map((op) => op.metaAttribute.data.title),
          ...gemstoneOptions.map((op) => op.metaAttribute.data.title),
          ...pearlOptions.map((op) => op.metaAttribute.data.title),
        ].join(" • ")}
      </small>
      <KeyboardArrowDownIcon
        sx={{
          fontSize: 18,
          marginLeft: 1,
          color: "#c8d8ff",
          cursor: "pointer",
        }}
        variant="contained"
        onClick={handleOpen}
      />
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <div className="py-3 px-2">
          {!!sizeAttribute &&
            sizeOptions.length > 0 &&
            sizeOptions.map((op) => (
              <div
                key={op.metaAttribute._id}
                className="d-flex align-items-center justify-content-between mb-2 text-lightBlue">
                <small>{sizeAttribute.frontEndTitle}:</small>
                <small className="ms-2">{op.metaAttribute.data.title}</small>
              </div>
            ))}
          {!!metalAttribute &&
            metalOptions.length > 0 &&
            metalOptions.map((op) => (
              <Fragment key={op.metaAttribute._id}>
                <div className="d-flex align-items-center justify-content-between mb-2 text-lightBlue">
                  <small>{metalAttribute.frontEndTitle}:</small>
                  <small className="ms-2">{op.metaAttribute.data.title}</small>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2 text-lightBlue">
                  <small>{op.metaAttribute.metaSubAttribute1.data.title}:</small>
                  <small className="ms-2">
                    {op.metaAttribute.metaSubAttribute1.metaSubAttributeValue.title}
                  </small>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2 text-lightBlue">
                  <small>{op.metaAttribute.metaSubAttribute2.data.title}:</small>
                  <small className="ms-2">
                    {op.metaAttribute.metaSubAttribute2.metaSubAttributeValue.title}
                  </small>
                </div>
              </Fragment>
            ))}
          {!!diamondAttribute &&
            diamondOptions.length > 0 &&
            diamondOptions.map((op) => (
              <div
                key={op.metaAttribute._id}
                className="d-flex align-items-center justify-content-between mb-2 text-lightBlue">
                <small>{diamondAttribute.frontEndTitle}:</small>
                <small className="ms-2">{op.metaAttribute.data.title}</small>
              </div>
            ))}
          {!!gemstoneAttribute &&
            gemstoneOptions.length > 0 &&
            gemstoneOptions.map((op) => (
              <div
                key={op.metaAttribute._id}
                className="d-flex align-items-center justify-content-between mb-2 text-lightBlue">
                <small>{gemstoneAttribute.frontEndTitle}:</small>
                <small className="ms-2">{op.metaAttribute.data.title}</small>
              </div>
            ))}
          {!!pearlAttribute &&
            pearlOptions.length > 0 &&
            pearlOptions.map((op) => (
              <div
                key={op.metaAttribute._id}
                className="d-flex align-items-center justify-content-between mb-2 text-lightBlue">
                <small>{pearlAttribute.frontEndTitle}:</small>
                <small className="ms-2">{op.metaAttribute.data.title}</small>
              </div>
            ))}
          <div className="d-flex align-items-center justify-content-between mb-2 text-lightBlue">
            <small>Net Weight (gms):</small>
            <small className="ms-2">{Math.round(variant.netWeight)}</small>
          </div>
          {!!metalWeightObj && (
            <div className="d-flex align-items-center justify-content-between mb-2 text-grey-6">
              <small>Metal Weight (gms):</small>
              <small className="ms-2">{metalWeightObj.weight}</small>
            </div>
          )}
          {!!diamondWeightObj && (
            <div className="d-flex align-items-center justify-content-between mb-2 text-grey-6">
              <small>Diamond Weight (ct):</small>
              <small className="ms-2">{diamondWeightObj.weight}</small>
            </div>
          )}
          {!!gemstoneWeightObj && (
            <div className="d-flex align-items-center justify-content-between mb-2 text-grey-6">
              <small>Gemstone Weight (ct):</small>
              <small className="ms-2">{gemstoneWeightObj.weight}</small>
            </div>
          )}
          {!!pearlWeightObj && (
            <div className="d-flex align-items-center justify-content-between mb-2 text-grey-6">
              <small>Pearl Weight (ct):</small>
              <small className="ms-2">{pearlWeightObj.weight}</small>
            </div>
          )}
        </div>
      </Popover>
    </>
  );
}
