import { useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import SideStoreBox from "../../../components/Setting/Store/SideBox.js";
import {
  EnhancedTableHeadNoCheckbox,
  stableSortNoCheckbox,
  getComparatorNoCheckbox,
} from "../../../components/TableDependenciesNoCheckbox/TableDependenciesNoCheckbox.js";
import AddAddress from "../../../components/AddAddress/AddAddress.js";
import PopUpBox from "../../../components/Setting/Store/PopUpBox.js";
import ContactStore from "./PopUp/ContactStore.js";

import subscription from "../../../assets/setting/icons/subscription.svg";
import trial from "../../../assets/setting/icons/trial.svg";
import InviteEntrepreneur from "./PopUp/InviteEntrepreneur.js";
import { useGetAllStoreDetailsQuery } from "../../../features/storeDetails/storeInfoApiSlice.js";

const headCells = [
  {
    id: "userName",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "groups",
    numeric: false,
    disablePadding: false,
    label: "Items",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Total",
  },
  {
    id: "orders",
    numeric: false,
    disablePadding: false,
    label: "Payment",
  },
];

function createLikeProductData(pId, productName, category, price) {
  return { pId, productName, category, price };
}

const likeProductRows = [
  createLikeProductData(
    1,
    "The Fringe Diamond Ring",
    "Gold Products",
    "₹ 25,000"
  ),
  createLikeProductData(2, "Fringe Diamond Ring", "Gold Products", "₹ 25,000"),
  createLikeProductData(
    3,
    "The Fringe Diamond Ring",
    "Gold Products",
    "₹ 25,000"
  ),
];

const BillingPlans = () => {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("productName");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAddress, setAddress] = useState(false);
  const [showTimeZone, setTimeZoneFormat] = useState(false);
  const [subscribe, setSubscribe] = useState(true);
  const [showContact, setContact] = useState(false);
  const [showInvitation, setInvitation] = useState(false);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleAddressClose = () => {
    setAddress(false);
  };

  const openAddressModal = () => {
    setAddress(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleLikeSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = likeProductRows.map((n) => n.pId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleTimeZone = () => {
    setTimeZoneFormat(false);
  };

  const openEditTimezone = () => {
    if (subscribe) {
      setTimeZoneFormat(true);
    }
  };

  const subscribeHandle = () => {
    openEditTimezone();
  };

  const handleContact = () => {
    setContact(false);
  };

  const contactClick = () => {
    setContact(true);
  };

  const extensionClick = () => {
    setContact(false);
    setTimeZoneFormat(false);
  };

  const subscribeClick = () => {
    setTimeZoneFormat(false);
    setSubscribe(false);
  };

  const handleInvite = () => {
    setInvitation(false);
  };

  const openInvitation = () => {
    setInvitation(true);
  };

  const subscribeImg = subscribe ? trial : subscription;
  const buttonName = subscribe ? "Subscribe" : "Pay ₹ 2000 in Advance";

  const { data, isLoading } = useGetAllStoreDetailsQuery();
  const billingAddress = useMemo(
    () => data?.data?.data?.[0]?.billingAddress,
    [data]
  );

  return (
    <div className="col-lg-12 mt-3">
      <div className="row mb-2">
        <SideStoreBox
          heading={"Subscription Paytment"}
          details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quia error quasi nihil expedita amet, officia sapiente. `}
        />

        <div className="col-lg-8 d-flex align-items-center">
          <Paper
            sx={{ width: "100%", mb: 0, mt: 0, p: 0 }}
            className="bg-black-13 px-4 py-4"
          >
            <Paper
              sx={{ width: "100%", mb: 0, mt: 0, p: 0 }}
              className="bg-black-20 px-3 py-3 mb-4"
            >
              <div className="row">
                <div className="col-lg-1 d-flex align-items-center">
                  <img src={subscribeImg} width={30} height={30} />
                </div>
                <div className="col-lg-8 d-flex">
                  {subscribe ? (
                    <>
                      <small className="text-lightBlue mt-2 d-block">
                        14 days free trial activated
                        <small className="text-grey-6">
                          {" "}
                          All featured all included
                          <small className="text-blue-2"> View all</small>
                        </small>
                      </small>
                    </>
                  ) : (
                    <small className="text-grey-6 mt-2 d-block">
                      The next monthly recurring charge will be made on
                      <small className="text-lightBlue"> May 13, 2023.</small>
                    </small>
                  )}
                </div>
                <div className="col-lg-3 d-flex align-items-center">
                  <small className="text-lightBlue mt-2 d-block">
                    ₹ 2000
                    <small className="text-grey-6"> per month</small>
                    <small className="text-grey-6">
                      {subscribe && " After trial period"}
                    </small>
                  </small>
                </div>
              </div>
            </Paper>
            <div className="d-flex justify-content-between">
              <small className="text-grey-6 mt-2 d-block mr-2">
                The next monthly recurring charge will be made on
                <small className="text-lightBlue"> May 13, 2023.</small>
              </small>
              <div
                className="button-gradient py-2 px-4 ml-3"
                onClick={subscribeHandle}
              >
                <p>{buttonName}</p>
              </div>
            </div>
          </Paper>
        </div>
      </div>

      <hr className="hr-grey-6 my-4 w-100" />

      <div className="row mb-2">
        <SideStoreBox
          heading={"Billing History"}
          details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quia error quasi nihil expedita amet, officia sapiente. `}
        />

        <div className="col-lg-8 d-flex align-items-center bg-black-13 rounded-8">
          <div className="row align-items-start">
            <TableContainer className="mt-3">
              <Table aria-labelledby="tableTitle" size="small">
                <EnhancedTableHeadNoCheckbox
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleLikeSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={likeProductRows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {stableSortNoCheckbox(
                    likeProductRows,
                    getComparatorNoCheckbox(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.pId);

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.pId}
                          selected={isItemSelected}
                        >
                          <TableCell>
                            <small className="text-lightBlue fs-10">
                              01/09/22 at 9:23pm
                            </small>
                          </TableCell>
                          <TableCell>
                            <small className="text-lightBlue fs-10">
                              Unthread Payment
                            </small>
                          </TableCell>
                          <TableCell>
                            <small className="text-lightBlue fs-10">
                              ₹ 2,500
                            </small>
                          </TableCell>
                          <TableCell>
                            <small className="text-lightBlue fs-10">
                              Paid by Master Card(Card ending with 9876)
                            </small>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <hr className="hr-grey-6 my-4 w-100" />

      <div className="row mb-2">
        <SideStoreBox
          heading={"Invite an entrepreneur"}
          details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quia error quasi nihil expedita amet, officia sapiente. `}
        />

        <div className="col-lg-8 d-flex align-items-center">
          <Paper
            sx={{ width: "100%", mb: 0, mt: 0, p: 0 }}
            className="bg-black-13 px-4 py-5"
          >
            <div className="col-lg-8 d-flex align-items-center">
              <div
                className="button-gradient py-2 px-4 ms-3"
                onClick={openInvitation}
              >
                <p>Invite an entrepreneur</p>
              </div>
            </div>
          </Paper>
        </div>
      </div>
      {/* {!isLoading && (
        <AddAddress
          openUser={openAddress}
          handleAddressClose={handleAddressClose}
          billingAddress={billingAddress}
          idData={data?.data?.data?.[0]?._id}
        />
      )} */}
      <PopUpBox
        show={showTimeZone}
        onConfirm={handleTimeZone}
        contactClick={contactClick}
        subscribeClick={subscribeClick}
      />
      <ContactStore
        show={showContact}
        onConfirm={handleContact}
        extensionClick={extensionClick}
      />
      <InviteEntrepreneur show={showInvitation} onConfirm={handleInvite} />
    </div>
  );
};

export default BillingPlans;
