import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import "./AllOrders.scss";
// ! COMPONENT IMPORTS
import AllOrdersTable from "./AllOrdersTable";
import ViewTutorial from "../../../components/ViewTutorial/ViewTutorial";
import ViewLogsDrawer from "../../../components/ViewLogsDrawer/ViewLogsDrawer";
import ImportSecondDialog from "../../../components/ImportSecondDialog/ImportSecondDialog";
import {
  TableSearchSecondary,
  TableSearchTertiary,
} from "../../../components/TableSearch/TableSearch";
import FilterOrders, { OrderDates, OrderStatuses, PaymentStatuses } from "./FilterOrders";
import OrderSortFilter from "./OrderSortFilter";
// import OrderColumnsFilter from "../OrderColumnsFilter";
import OrderTagsFilter from "../OrderTagsFilter";
import OrderStatusFilter from "../OrderStatusFilter";
// import OrderLocationFilter from "../OrderLocationFilter";
// ! IMAGES IMPORTS
import indiaFlag from "../../../assets/images/products/indiaFlag.svg";
// import allFlag from "../../../assets/images/products/allFlag.svg";
// import usaFlag from "../../../assets/images/products/usaFlag.svg";
// import ukFlag from "../../../assets/images/products/ukFlag.svg";
import arrowDown from "../../../assets/icons/arrowDown.svg";
// import analyticsUp from "../../../assets/icons/analyticsUp.svg";
// import analyticsDown from "../../../assets/icons/analyticsDown.svg";
import orders from "../../../assets/icons/sidenav/orders.svg";
// ! MATERIAL IMPORTS
import { Box, Button, Chip, Paper, Popover, Tab, Tabs } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  useGetAllOrderCountsQuery,
  useGetAllOrdersQuery,
  useGetOrderAnalyticsQuery,
} from "../../../features/orders/ordersApiSlice";
import PaymentStatusFilter from "../PaymentStatusFilter";
// import OrderVendorFilter from "../OrderVendorFilter";
// import { useDispatch } from "react-redux";
// import { showError } from "../../../features/snackbar/snackbarAction";
import moment from "moment/moment";
// import ExportDialogOrder from "../../../components/ExportDialog/ExportDialogOrder";
import AmountSpentFilter from "../AmountSpentFilter";
import { rupeeFormatWithSymbol } from "../../../utils/format";
import { useGetOrderCsvMutation } from "../../../features/importExport/order/orderApiSlice";
import ExportDialogCustom from "../../../components/ExportDialog/ExportDialogCustom";

const initialQueryFilterState = {
  pageSize: 10,
  pageNo: 1,
  tabIndex: 0,
  search: "",
  itemName: "",
  minPrice: 0,
  maxPrice: 0,
  tagManager: "",
  status: [],
  paymentStatus: [],
  orderDate: "",
  location: "",
  vendor: "",
  noofItem: "",
  srNo: "",
  itemSort: "",
  priceSort: "",
  createdAt: "-1",
  updatedAt: "",
  state: "create",
};

const queryFilterReducer = (state, action) => {
  switch (action.type) {
    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        pageSize: +action.pageSize,
      };
    case "CHANGE_PAGE":
      return {
        ...state,
        pageNo: +action.pageNo,
      };
    case "SEARCH":
      console.log(action);
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        search: action.search,
        itemName: action.itemName,
      };
    case "SEARCH_TAG":
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        tagManager: action.tag,
      };
    case "SET_SORTING":
      const {
        srNo = "",
        itemSort = "",
        priceSort = "",
        createdAt = "",
        updatedAt = "",
      } = action;
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        srNo: srNo,
        itemSort: itemSort,
        priceSort: priceSort,
        createdAt: createdAt,
        updatedAt: updatedAt,
      };
    case "SET_STATUS":
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        status: action.status,
      };
    case "SET_PAYMENT_STATUS":
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        paymentStatus: action.paymentStatus,
      };
    case "SET_TAB_INDEX":
      return {
        ...initialQueryFilterState,
        ...action.state,
      };
    case "SET_MIN_PRICE":
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        minPrice: action.value,
      };
    case "SET_MAX_PRICE":
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        maxPrice: action.value,
      };
    case "SET_RANGE_PRICE":
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        minPrice: action.minPrice,
        maxPrice: action.maxPrice,
      };
    case "SET_LOCATION":
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        location: action.location,
      };
    case "SET_VENDOR":
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        vendor: action.vendor,
      };
    case "SET_ITEMS":
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        noofItem: action.noofItem,
      };
    case "SET_ORDER_DATE":
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        orderDate: action.orderDate,
      };
    case "SET_ALL_FILTERS":
      return {
        ...initialQueryFilterState,
        ...state,
        ...action.filter,
      };
    default:
      return initialQueryFilterState;
  }
};

export default function AllOrders() {
  // const dispatch = useDispatch();

  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );

  const [selected, setSelected] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [firstRender, setFirstRender] = useState(true);
  const [analyticsFrom, setAnalyticsFrom] = useState(30);
  const [searchValue, setSearchValue] = useState("");
  const [itemNameValue, setItemNameValue] = useState("o");
  const [chipData, setChipData] = useState([]);

  const { data: orderAnalyticsData } = useGetOrderAnalyticsQuery({
    from: moment().subtract(analyticsFrom, "day").format("YYYY-MM-DD"),
  });
  const orderAnalytics = orderAnalyticsData?.data ?? {
    totalOrder: 0,
    totalRevenue: 0,
    totalFullfillItem: 0,
  };

  const { data: orderCount } = useGetAllOrderCountsQuery();
  const {
    data: orderData,
    isLoading: ordersLoading,
    isSuccess: ordersSuccess,
    error: orderError,
  } = useGetAllOrdersQuery({ ...queryFilterState, vendor: queryFilterState?.vendor?._id });
  const allOrders = orderData?.data?.data ?? [];
  const totalOrderCount = orderData?.data?.totalCount ?? 0;

  // * FLAG POPOVERS STARTS
  // const [anchorFlagEl, setAnchorFlagEl] = React.useState(null);
  // const handleFlagClick = (event) => {
  //   setAnchorFlagEl(event.currentTarget);
  // };
  // const handleFlagClose = () => {
  //   setAnchorFlagEl(null);
  // };
  // const openFlag = Boolean(anchorFlagEl);
  // const idFlag = openFlag ? "simple-popover" : undefined;
  // * FLAG POPOVERS ENDS

  // * DAYS POPOVERS STARTS
  const [anchorDaysEl, setDaysEl] = React.useState(null);

  const handleDaysClick = (event) => {
    setDaysEl(event.currentTarget);
  };

  const handleDaysClose = () => {
    setDaysEl(null);
  };

  const openDays = Boolean(anchorDaysEl);
  const idDays = openDays ? "simple-popover" : undefined;
  // * DAYS POPOVERS ENDS
  // ? POPOVERS ENDS HERE

  const onTabIndexChange = (_, index) => {
    switch (index) {
      case 0: {
        dispatchQueryFilter({
          type: "SET_TAB_INDEX",
          state: {
            tabIndex: 0,
            status: [],
          },
        });
        setSelected([]);
        break;
      }
      case 1: {
        dispatchQueryFilter({
          type: "SET_TAB_INDEX",
          state: {
            tabIndex: 1,
            orderDate: OrderDates[2].value,
          },
        });
        setSelected([]);
        break;
      }
      case 2: {
        dispatchQueryFilter({
          type: "SET_TAB_INDEX",
          state: {
            tabIndex: 2,
            status: ["delivered"],
          },
        });
        setSelected([]);
        break;
      }
      case 3: {
        dispatchQueryFilter({
          type: "SET_TAB_INDEX",
          state: {
            tabIndex: 3,
            status: ["archieved"],
          },
        });
        setSelected([]);
        break;
      }

      default:
        break;
    }
  };

  const onChangeRowsPerPage = (event) => {
    dispatchQueryFilter({ type: "SET_PAGE_SIZE", pageSize: event.target.value });
    setSelected([]);
  };
  const onChangePage = (_, pageNo) => {
    dispatchQueryFilter({ type: "CHANGE_PAGE", pageNo: pageNo + 1 });
    setSelected([]);
  };

  const clearAllFilter = () => {
    const {
      pageNo,
      minPrice,
      maxPrice,
      search,
      tagManager,
      status,
      paymentStatus,
      orderDate,
      location,
      noofItem,
      srNo,
      createdAt,
      updatedAt,
    } = initialQueryFilterState;
    setSearchValue("");
    setItemNameValue("o");
    setSelected([]);
    dispatchQueryFilter({
      type: "SET_ALL_FILTERS",
      filter: {
        pageNo,
        minPrice,
        maxPrice,
        search,
        tagManager,
        status,
        paymentStatus,
        orderDate,
        location,
        noofItem,
        srNo,
        createdAt,
        updatedAt,
      },
    });
  };

  const deleteChip = (type) => {
    // ["price", "tag", "order", "payment", "date", "loc", "item"]
    switch (type) {
      case "price": {
        dispatchQueryFilter({
          type: "SET_RANGE_PRICE",
          minPrice: 0,
          maxPrice: 0,
        });
        setSelected([]);
        break;
      }
      case "tag": {
        dispatchQueryFilter({
          type: "SEARCH_TAG",
          tagManager: "",
        });
        setSelected([]);
        break;
      }
      case "order": {
        dispatchQueryFilter({
          type: "SET_STATUS",
          status: [],
        });
        setSelected([]);
        break;
      }
      case "payment": {
        dispatchQueryFilter({
          type: "SET_PAYMENT_STATUS",
          paymentStatus: [],
        });
        setSelected([]);
        break;
      }
      case "date": {
        dispatchQueryFilter({
          type: "SET_ORDER_DATE",
          orderDate: "",
        });
        setSelected([]);
        break;
      }
      case "loc": {
        dispatchQueryFilter({
          type: "SET_LOCATION",
          location: "",
        });
        setSelected([]);
        break;
      }
      case "vend": {
        dispatchQueryFilter({
          type: "SET_VENDOR",
          vendor: null,
        });
        setSelected([]);
        break;
      }
      case "item": {
        dispatchQueryFilter({
          type: "SET_ITEMS",
          noofItem: "",
        });
        setSelected([]);
        break;
      }

      default: {
        const {
          pageNo,
          minPrice,
          maxPrice,
          tagManager,
          status,
          paymentStatus,
          orderDate,
          location,
          vendor,
          noofItem,
        } = initialQueryFilterState;
        dispatchQueryFilter({
          type: "SET_ALL_FILTERS",
          filter: {
            pageNo,
            minPrice,
            maxPrice,
            tagManager,
            status,
            paymentStatus,
            orderDate,
            location,
            vendor,
            noofItem,
          },
        });
        setSelected([]);
        break;
      }
    }
  };

  const debKeyChange = useCallback(
    (val) => {
      dispatchQueryFilter({
        type: "SEARCH",
        search: itemNameValue === "i" ? "" : val,
        itemName: itemNameValue === "i" ? val : "",
      });
      setSelected([]);
    },
    [itemNameValue]
  );

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      if (searchParams.has("filter")) {
        try {
          const filter = JSON.parse(searchParams.get("filter"));
          dispatchQueryFilter({ type: "SET_ALL_FILTERS", filter });
          setSelected([]);
          setChipData(getChipData(filter));
        } catch (error) {
          console.warn(error);
          dispatchQueryFilter({ type: "SET_ALL_FILTERS", filter: {} });
          setSelected([]);
        }
      }
    } else {
      // console.log(queryFilterState);
      setSearchParams({ filter: JSON.stringify(queryFilterState) });
      setChipData(getChipData(queryFilterState));
    }
  }, [queryFilterState, setSearchParams, firstRender, searchParams]);

  return (
    <div className="container-fluid page">
      <div className="row justify-content-between align-items-center">
        <h4 className="page-heading w-auto ps-0">Orders</h4>
        <div className="d-flex align-items-center w-auto pe-0">
          {/* <ViewTutorial /> */}
          <ViewLogsDrawer
            headingName={"Orders Module"}
            icon={orders}
            module="order"
          />
          <ExportDialogCustom
            dialogName="Orders"
            queryFilters={queryFilterState}
            currentSelection={selected}
            mutation={useGetOrderCsvMutation}
          />
          {/* <ExportDialogOrder
            dialogName="Orders"
            currentPage={queryFilterState.pageNo}
            currentSize={queryFilterState.pageSize}
            totalCount={totalOrderCount}
            mutation={useGetOrderCsvMutation}
          /> */}
          <ImportSecondDialog dialogName={"Orders"} />
          <Link
            to="/orders/allOrders/create"
            className="button-gradient py-2 px-4">
            <p>+ Create Order</p>
          </Link>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-3 col-6 ps-0 my-3 d-flex">
          <div className="border-grey-5 bg-black-15 rounded-8 py-3 px-3 flex-grow-1">
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex flex-column">
                <h2 className="text-lightBlue fw-400">{orderAnalytics.totalOrder}</h2>
                <small className="text-grey-6 mt-2">Orders</small>
              </div>
              {/* <div className="d-flex flex-column align-items-end">
                <img
                  src={analyticsUp}
                  alt="analyticsUp"
                  className=""
                  width={25}
                />
                <small className="text-green-2 mt-3">+10.78 %</small>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-3 col-6 ps-0 my-3 d-flex">
          <div className="border-grey-5 bg-black-15 rounded-8 py-3 px-3 flex-grow-1">
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex flex-column">
                <h2 className="text-lightBlue fw-400">
                  {rupeeFormatWithSymbol(orderAnalytics.totalRevenue)}
                </h2>
                <small className="text-grey-6 mt-2">Sales Amount</small>
              </div>
              {/* <div className="d-flex flex-column align-items-end">
                <img
                  src={analyticsDown}
                  alt="analyticsDown"
                  className=""
                  width={25}
                />
                <small className="text-red-4 mt-3">-10.78 %</small>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-3 col-6 ps-0 my-3 d-flex">
          <div className="border-grey-5 bg-black-15 rounded-8 py-3 px-3 flex-grow-1">
            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex flex-column">
                <h2 className="text-lightBlue fw-400">{orderAnalytics.totalFullfillItem}</h2>
                <small className="text-grey-6 mt-2">Fulfillment Items</small>
              </div>
              {/* <div className="d-flex flex-column align-items-end">
                <img
                  src={analyticsUp}
                  alt="analyticsUp"
                  className=""
                  width={25}
                />
                <small className="text-green-2 mt-3 text-nowrap">+10.78 %</small>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-3 col-6 ps-0 pe-0 my-3 d-flex">
          <div
            onClick={handleDaysClick}
            className="border-grey-5 bg-black-15 rounded-8 py-3 px-3 text-center flex-grow-1 d-flex align-items-center justify-content-center c-pointer">
            <h3 className="text-lightBlue">{analyticsFrom} Days</h3>
            <KeyboardArrowDownIcon
              sx={{
                fontSize: 30,
                marginLeft: 1,
                color: "#c8d8ff",
                cursor: "pointer",
                marginTop: "2px",
              }}
            />
          </div>
          <Popover
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            id={idDays}
            open={openDays}
            anchorEl={anchorDaysEl}
            onClose={handleDaysClose}
            onClick={handleDaysClose}>
            <div className="py-2 px-1">
              <small
                onClick={() => setAnalyticsFrom(7)}
                className="text-lightBlue rounded-3 p-2 hover-back d-block c-pointer">
                Last 7 Days
              </small>
              <small
                onClick={() => setAnalyticsFrom(15)}
                className="text-lightBlue rounded-3 p-2 hover-back d-block c-pointer">
                Last 15 Days
              </small>
              <small
                onClick={() => setAnalyticsFrom(30)}
                className="text-lightBlue rounded-3 p-2 hover-back d-block c-pointer">
                Last 30 Days
              </small>
              {/* <small className="text-blue-gradient rounded-3 p-2 d-block c-pointer">
                Custom Date
              </small> */}
            </div>
          </Popover>
        </div>
      </div>

      <div className="row">
        <Paper
          sx={{ width: "100%", mb: 0, mt: 0, p: 0 }}
          className="border-grey-5 bg-black-15">
          <Box
            sx={{ width: "100%" }}
            className="d-flex justify-content-between tabs-header-box">
            <Tabs
              value={queryFilterState.tabIndex}
              onChange={onTabIndexChange}
              aria-label="scrollable force tabs example"
              className="tabs">
              <Tab
                label={`All (${orderCount?.all})`}
                className="tabs-head"
              />
              <Tab
                label={`New (${orderCount?.new})`}
                className="tabs-head"
              />
              <Tab
                label={`Complete (${orderCount?.delivered})`}
                className="tabs-head"
              />
              <Tab
                label={`Archived (${orderCount?.archived})`}
                className="tabs-head"
              />
            </Tabs>
            <div
              // onClick={handleFlagClick}
              className="tabs-country c-pointer">
              <img
                src={indiaFlag}
                alt="indiaFlag"
                height={15}
              />
              <p className="mx-2 text-lightBlue">India</p>
              <img
                src={arrowDown}
                alt="arrowDown"
              />
            </div>
          </Box>
          <div className="d-flex align-items-center mt-3 mb-3 px-2 justify-content-between">
            {console.log({ searchValue, itemNameValue })}
            <TableSearchTertiary
              search={searchValue}
              field={itemNameValue}
              onFiledChange={(val) => setItemNameValue(val)}
              onKeyChange={(val) => setSearchValue(val)}
              debKeyChange={debKeyChange}
            />
            <div className="d-flex ms-2">
              <div className="d-flex product-button__box">
                {/* <OrderLocationFilter
                  value={queryFilterState.location}
                  onChange={(val) =>
                    dispatchQueryFilter({ type: "SET_LOCATION", location: val })
                  }
                />
                <OrderVendorFilter
                  value={queryFilterState.vendor}
                  onChange={(val) => dispatchQueryFilter({ type: "SET_VENDOR", vendor: val })}
                /> */}
                {queryFilterState.tabIndex < 2 && (
                  <OrderStatusFilter
                    list={queryFilterState.status}
                    onChange={(val) => {
                      dispatchQueryFilter({ type: "SET_STATUS", status: val });
                      setSelected([]);
                    }}
                  />
                )}
                <PaymentStatusFilter
                  list={queryFilterState.paymentStatus}
                  onChange={(val) => {
                    dispatchQueryFilter({ type: "SET_PAYMENT_STATUS", paymentStatus: val });
                    setSelected([]);
                  }}
                />
                <AmountSpentFilter
                  minQueryPrice={queryFilterState.minPrice}
                  maxQueryPrice={queryFilterState.maxPrice}
                  onChange={(min, max) => {
                    dispatchQueryFilter({
                      type: "SET_RANGE_PRICE",
                      minPrice: min,
                      maxPrice: max,
                    });
                    setSelected([]);
                  }}
                />
                <OrderTagsFilter
                  value={queryFilterState.tagManager}
                  onChange={(val) => {
                    dispatchQueryFilter({ type: "SEARCH_TAG", tag: val });
                    setSelected([]);
                  }}
                />
                <FilterOrders
                  queryFilters={queryFilterState}
                  onOrderStatusChange={(val) => {
                    dispatchQueryFilter({ type: "SET_STATUS", status: val });
                    setSelected([]);
                  }}
                  onPaymentStatusChange={(val) => {
                    dispatchQueryFilter({ type: "SET_PAYMENT_STATUS", paymentStatus: val });
                    setSelected([]);
                  }}
                  onPriceRangeChange={(min, max) => {
                    dispatchQueryFilter({
                      type: "SET_RANGE_PRICE",
                      minPrice: min,
                      maxPrice: max,
                    });
                    setSelected([]);
                  }}
                  onItemsChange={(val) => {
                    dispatchQueryFilter({ type: "SET_ITEMS", noofItem: val });
                    setSelected([]);
                  }}
                  onTagChange={(val) => {
                    dispatchQueryFilter({ type: "SEARCH_TAG", tag: val });
                    setSelected([]);
                  }}
                  onOrderDateChange={(val) => {
                    dispatchQueryFilter({ type: "SET_ORDER_DATE", orderDate: val });
                    setSelected([]);
                  }}
                  onLocationChange={(val) => {
                    dispatchQueryFilter({ type: "SET_LOCATION", location: val });
                    setSelected([]);
                  }}
                  onVendorChange={(val) => {
                    dispatchQueryFilter({ type: "SET_VENDOR", vendor: val });
                    setSelected([]);
                  }}
                  onResetAllFilters={clearAllFilter}
                />
              </div>
              <OrderSortFilter
                queryFilters={queryFilterState}
                onSortChange={(change) => {
                  dispatchQueryFilter({ type: "SET_SORTING", ...change });
                  setSelected([]);
                }}
              />
              {/* <OrderColumnsFilter /> */}
            </div>
          </div>
          {chipData?.length > 0 && (
            <div className="d-flex flex-wrap align-items-center mb-3 px-2">
              {chipData?.map((item) => (
                <Chip
                  key={item.type}
                  label={item.text}
                  onDelete={() => deleteChip(item.type)}
                  className="mx-1"
                  size="small"
                />
              ))}
              <Button
                size="small"
                variant="text"
                className="mx-1"
                onClick={() => deleteChip("")}>
                <p className="text-blue-2">Clear all</p>
              </Button>
            </div>
          )}
          <AllOrdersTable
            tabIndex={queryFilterState.tabIndex}
            page={queryFilterState.pageNo}
            changePage={onChangePage}
            changeRowsPerPage={onChangeRowsPerPage}
            rowsPerPage={queryFilterState.pageSize}
            totalCount={totalOrderCount}
            orderList={allOrders}
            orderLoading={ordersLoading}
            hasError={orderError || !ordersSuccess}
            selected={selected}
            setSelected={setSelected}
          />
        </Paper>
      </div>
    </div>
  );
}

// ["price", "tag", "order", "payment", "date", "loc", "item"]
function getChipData(queries = {}) {
  const {
    tabIndex = 0,
    minPrice = 0,
    maxPrice = 0,
    tagManager = "",
    status = [],
    paymentStatus = [],
    orderDate = "",
    location = "",
    vendor = null,
    noofItem = "",
  } = queries;
  let chips = [];
  if (minPrice !== 0 || maxPrice !== 0)
    chips.push({ type: "price", text: `Price Range between ${minPrice} - ${maxPrice}.` });
  if (status.length > 0 && tabIndex < 2)
    chips.push({
      type: "order",
      text: `Order status is ${status.map(
        (st) => OrderStatuses.find((os) => os.value === st)?.text ?? ""
      )}.`,
    });
  if (paymentStatus.length > 0)
    chips.push({
      type: "payment",
      text: `Payment status is ${paymentStatus.map(
        (st) => PaymentStatuses.find((ps) => ps.value === st)?.text ?? ""
      )}.`,
    });
  if (!!orderDate && tabIndex !== 1)
    chips.push({
      type: "date",
      text: `Orders since ${OrderDates.find((od) => od.value === orderDate)?.text ?? ""}.`,
    });
  if (!!location) chips.push({ type: "loc", text: `Orders shipped to ${location}.` });
  if (!!vendor) chips.push({ type: "vend", text: `Orders provided by ${vendor.name}.` });
  if (!!tagManager) chips.push({ type: "tag", text: `Orders tagged with ${tagManager}.` });
  if (!!noofItem) chips.push({ type: "item", text: `Orders with ${noofItem} items.` });
  return chips;
}

/* <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              id={idFlag}
              open={openFlag}
              anchorEl={anchorFlagEl}
              onClose={handleFlagClose}>
              <div className="px-1 py-2">
                <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
                  <img
                    src={allFlag}
                    alt="allFlag"
                    height={20}
                  />
                  <p className="ms-2 text-lightBlue">All</p>
                </div>
                <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
                  <img
                    src={ukFlag}
                    alt="usaFlag"
                    height={15}
                  />
                  <p className="ms-2 text-lightBlue">UK</p>
                </div>
                <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
                  <img
                    src={usaFlag}
                    alt="usaFlag"
                    height={15}
                  />
                  <p className="ms-2 text-lightBlue">USA</p>
                </div>
              </div>
            </Popover> */
