import { useMemo } from "react";
import Heading from "../../../components/Headers/DashboardHeader";
import product from "../../../assets/images/Components/collection.svg";
import { useGetTopSellingProductsQuery } from "../../../features/analytics/analyticsApiSlice";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";
import { Link } from "react-router-dom";

export default function MostSellingProducts({ filter = null }) {
  const { data } = useGetTopSellingProductsQuery(filter, { skip: !filter });

  const salesData = useMemo(
    () => data?.flatMap((wrap) => wrap?.top_products?.buckets ?? []) ?? [],
    [data]
  );

  return (
    <div className="bg-button-3 border-grey-5 px-3 py-3 rounded-8 mt-4">
      <Heading
        heading={"Most Selling Products"}
        // viewMore={"View More ──"}
      />
      <div className="d-flex row justify-content-between">
        <div className="mt-4 col-12 row gap-3">
          <div className="image-container">
            {salesData.map((item) => (
              <div
                key={item?.key}
                className="col-lg-3">
                <div className="d-flex justify-content-center">
                  <AsyncImage
                    src={item?.mediaUrl?.find((url) => url?.isDefault)?.image}
                    placeholder={product}
                    alt="product"
                    className="rounded-8"
                    height={160}
                    width={160}
                  />
                </div>

                <Link
                  to={`/products/allProducts/editProduct/${item?.key}`}
                  className="text-decoration-none">
                  <p
                    className="text-lightBlue text-center mt-3 mb-1"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>
                    {item?.product_name}
                  </p>
                </Link>
                <div className="d-flex align-items-center justify-content-center">
                  <p className="text-blue-gradient-2 mx-1">{item?.doc_count}</p>
                  <p className="text-lightBlue fw-400 mx-1">Orders</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
