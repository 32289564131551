import { Radio, Tooltip } from "@mui/material";
import info from "../../assets/icons/info.svg";
import { useGetActiveUsersQuery } from "../../features/analytics/analyticsApiSlice";
import { useEffect, useMemo, useState } from "react";

const currencySyms = ["", "", "K", "L", "C"];

const formatter = new Intl.NumberFormat("en-IN", {
  maximumSignificantDigits: 3,
});

const LiveVistor = () => {
  // const [prevActiveUsers, setPrevActiveUsers] = useState(0);
  const { data: activeUsersData, refetch } = useGetActiveUsersQuery({});
  const activeUsers = activeUsersData?.metricValues?.[0]?.value ?? 0;
  const formatted = formatter.format(activeUsers);
  const splitted = formatted.split(",").map((s) => s.replace(/0{2,3}/g, ""));
  const active = splitted.slice(0, 2).join(".") + currencySyms[splitted.length];

  // const deviationPercentage = useMemo(() => {
  //   if (prevActiveUsers === 0) return 0;
  //   else return Math.round(((activeUsers - prevActiveUsers) / prevActiveUsers) * 100);
  // }, [activeUsers, prevActiveUsers]);

  useEffect(() => {
    const iid = setInterval(() => {
      // setPrevActiveUsers(activeUsers);
      refetch();
    }, 6e4);
    return clearInterval(iid);
  }, [refetch]);

  return (
    <div className="bg-button-2 px-3 pt-2 pb-3 rounded-8">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <h4 className="text-lightBlue me-1 fw-400">Live Visitor</h4>
          <p className="text-red-4 live-visitor fw-500">
            <Radio
              checked
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 18,
                },
              }}
            />
          </p>
          {/* <Tooltip
            title="Lorem ipsum"
            placement="top">
            <img
              src={info}
              alt="info"
              className="c-pointer"
              width={18}
            />
          </Tooltip> */}
        </div>
        <div className="d-flex align-items-center">
          {/* <h6
            className={`me-3 fw-500 ${
              deviationPercentage < 0 ? "text-red-4" : "text-green-2"
            }`}>
            {deviationPercentage < 0 ? "-" : "+"}
            {Math.abs(deviationPercentage)}%
          </h6> */}
          <div className="fs-3 text-blue-gradient fw-600">{active}</div>
        </div>
      </div>
      {/* <div className="d-flex justify-content-between mt-1">
        <p className="text-lightBlue">
          Its a right time to
          <small className="link-blue-gradient">Boost Ads</small>
        </p>
        <p className="text-red-4 live-visitor fw-500">
          <Radio
            checked
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
          Live Visitor
        </p>
      </div> */}
    </div>
  );
};

export default LiveVistor;
