import { useEffect, useState } from "react";
import "./AppNavbarNotifications.scss";
// ! COMPONENT IMPORTS
// import TabPanel from "../TabPanel/TabPanel";
// ! IMAGES IMPORTS
// import arrowDown from "../../assets/icons/arrowDown.svg";
import notificationNavbar from "../../assets/icons/notificationNavbar.svg";
import cancel from "../../assets/icons/cancel.svg";
// ! MATERIAL IMPORTS
import { Badge, Button, IconButton, Popover } from "@mui/material";
// ! MATERIAL ICONS IMPORTS
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
// import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import {
  useGetNotificationCountQuery,
  useGetNotificationsQuery,
  useUpdateManyNotificationsMutation,
  useUpdateNotificationMutation,
} from "../../features/notifications/notifications";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { showError } from "../../features/snackbar/snackbarAction";
import NoDataFound from "../NoDataFound/NoDataFound";

const AppNavbarNotifications = () => {
  const dispatch = useDispatch();
  // ? POPPER STARTS HERE
  const [anchorPopperEl, setAnchorPopperEl] = useState(null);
  const handlePopperClick = (event) => {
    setAnchorPopperEl(event.currentTarget);
  };
  const handlePopperClose = () => {
    setAnchorPopperEl(null);
  };
  const openPopper = Boolean(anchorPopperEl);
  const idPopper = openPopper ? "simple-popper" : undefined;
  // ? POPPER ENDS HERE

  const [updateNotification] = useUpdateNotificationMutation();
  const [updateManyNotifications] = useUpdateManyNotificationsMutation();

  const { data, refetch } = useGetNotificationsQuery({ status: "active" });
  const { data: countData, refetch: countRefetch } = useGetNotificationCountQuery();

  const notifications = data?.data ?? [];
  const count = countData?.[0]?.active ?? 0;

  useEffect(() => {
    const iid = setInterval(() => {
      refetch();
      countRefetch();
    }, 6e4);
    return () => clearInterval(iid);
  }, [countRefetch, refetch]);

  const clearAllNotifications = () => {
    updateManyNotifications({
      updates: notifications.map((not) => ({
        id: not._id,
        status: "archived",
        read: true,
        seen: true,
      })),
    })
      .unwrap()
      .catch((e) => {
        console.log(e);
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Notification went wrong",
          })
        );
      });
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="show 17 new notifications"
        color="inherit"
        onClick={handlePopperClick}>
        <Badge
          badgeContent={count}
          color="purple"
          size="small">
          <NotificationsNoneIcon
            size="small"
            sx={{ color: "#c8d8ff" }}
          />
        </Badge>
      </IconButton>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopperClose}
        id={idPopper}
        open={openPopper}
        anchorEl={anchorPopperEl}
        sx={{ padding: 0 }}
        className="navbar-popover">
        <div className="d-flex flex-column my-3">
          <div className="d-flex align-items-center justify-content-between px-3 navbar-popover-heading">
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <h6 className="text-lightBlue fw-500 me-2">Notification</h6>
                {count > 0 && (
                  <Button
                    size="small"
                    variant="text"
                    onClick={clearAllNotifications}>
                    <small className="text-blue-2">Clear</small>
                  </Button>
                )}
              </div>
            </div>
            <img
              src={cancel}
              alt="cancel"
              width={25}
              onClick={handlePopperClose}
              className="c-pointer"
            />
          </div>
          <div className="px-3 nav-tab-panel">
            {notifications.length === 0 && <NoDataFound />}
            {notifications.map((notify) => (
              <div
                key={notify._id}
                className="d-flex flex-column justify-content-center border-grey-5 bg-black-20 p-3 rounded-8 my-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <small className="text-grey-6 font0">
                    {moment(notify.createdAt).format("DD-MM-YYYY [at] h:mm a")}
                  </small>
                  <IconButton
                    onClick={() => {
                      updateNotification({
                        id: notify._id,
                        details: {
                          status: "archived",
                          read: true,
                          seen: true,
                        },
                      })
                        .unwrap()
                        .catch((e) => {
                          console.log(e);
                          dispatch(
                            showError({
                              message:
                                e?.data?.message ?? e?.message ?? "Notification went wrong",
                            })
                          );
                        });
                    }}
                    sx={{ padding: 0 }}
                    size="small">
                    <HighlightOffOutlinedIcon
                      size="small"
                      aria-label="delete"
                      sx={{ color: "#c8d8ff" }}
                    />
                  </IconButton>
                </div>
                <div className="d-flex">
                  <img
                    src={notificationNavbar}
                    alt="notificationNavbar"
                    className="me-2"
                    width={40}
                  />
                  <p
                    className="text-lightBlue"
                    style={{ maxWidth: "320px" }}>
                    {notify.message}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default AppNavbarNotifications;
