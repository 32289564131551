import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import Heading from "../../../components/Headers/DashboardHeader";
import LiveVistor from "../../../components/LiveVistor/LiveVistor";
import SalesRatio from "../../Dashboard/SalesRatio";
import DoubleLineGraph from "../../../components/Chart/DoubleLineGraph";

import "./Analytics.scss";

import bharat from "../../../assets/flag/india.svg";
import maps from "../../../assets/dashboard/Maps.svg";
import product from "../../../assets/dashboard/sellingProducts.svg";
import usa from "../../../assets/flag/usa.svg";
import germany from "../../../assets/flag/germany.svg";
import download from "../../../assets/dashboard/Combined Shape.svg";
import calendar from "../../../assets/dashboard/calendar.png";
import tutorial from "../../../assets/icons/tutorial.svg";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useMemo, useState } from "react";
import TotalSales from "./TotalSales";
import TopSalesLocations from "./TopSalesLocations";
import MostSellingProducts from "./MostSellingProducts";
import TopOrders from "../../Dashboard/TopOrders";

const countries = [{ code: "IN", label: "1st Quater" }];

const rows = [
  { name: "Mirosa", price: "$500k", product: "45k Products" },
  { name: "Anant", price: "$300k", product: "35k Products" },
  { name: "Bridal Wear", price: "$100k", product: "20k Products" },
  { name: "Bridal Wear", price: "$100k", product: "20k Products" },
];

const rows2 = [
  { name: "Mirosa", price: "$500k", product: "45k Products", rank: 1 },
  { name: "Anant", price: "$300k", product: "35k Products", rank: 2 },
  { name: "Bridal Wear", price: "$100k", product: "20k Products", rank: 3 },
];

const rows3 = [
  {
    orderId: "#1234",
    data: "Today at 5.25pm",
    customer: "Sahil Bhutani",
    country: "India",
    flag: bharat,
    total: "₹ 5,00,000",
  },
  {
    orderId: "#1234",
    data: "Today at 5.25pm",
    customer: "Sahil Bhutani",
    country: "India",
    flag: bharat,
    total: "₹ 5,00,000",
  },
  {
    orderId: "#1234",
    data: "Today at 5.25pm",
    customer: "Sahil Bhutani",
    country: "India",
    flag: bharat,
    total: "₹ 5,00,000",
  },
  {
    orderId: "#1234",
    data: "Today at 5.25pm",
    customer: "Sahil Bhutani",
    country: "India",
    flag: bharat,
    total: "₹ 5,00,000",
  },
  {
    orderId: "#1234",
    data: "Today at 5.25pm",
    customer: "Sahil Bhutani",
    country: "India",
    flag: bharat,
    total: "₹ 5,00,000",
  },
  {
    orderId: "#1234",
    data: "Today at 5.25pm",
    customer: "Sahil Bhutani",
    country: "India",
    flag: bharat,
    total: "₹ 5,00,000",
  },
];

const headCells = [
  { name: "Order ID" },
  { name: "Date" },
  { name: "Customer" },
  { name: "Country" },
  { name: "Total" },
];

const salesData = [
  { percent: 92, image: bharat, name: "India", amount: "500,000k", ratio: "+10.5%" },
  { percent: 75, image: usa, name: "United States", amount: "5,000k", ratio: "+9.5%" },
  { percent: 60, image: germany, name: "Germany", amount: "1,000k", ratio: "+3.5%" },
  { percent: 92, image: bharat, name: "India", amount: "500,000k", ratio: "+10.5%" },
  { percent: 92, image: bharat, name: "India", amount: "500,000k", ratio: "+10.5%" },
];

const timeData = [
  { value: "week", name: "This Week" },
  { value: "month", name: "This Month" },
  { value: "year", name: "This Year" },
  { value: "", name: "Custom" },
];

const nowMoment = moment();

const Analytics = () => {
  const [timeFrame, setTimeFrame] = useState("week");
  const [fromDate, setFromDate] = useState(moment().subtract(1, "week").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(nowMoment.format("YYYY-MM-DD"));

  const filter = useMemo(() => {
    if (!timeFrame) return { to: toDate, from: fromDate };
    else return { type: timeFrame };
  }, [fromDate, timeFrame, toDate]);

  const onTimeFrameChange = (val) => {
    switch (val) {
      case "week":
        setTimeFrame("week");
        setFromDate(moment().subtract(1, "week").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;
      case "month":
        setTimeFrame("month");
        setFromDate(moment().subtract(1, "month").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;
      case "year":
        setTimeFrame("year");
        setFromDate(moment().subtract(1, "year").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;

      default:
        setTimeFrame("");
        break;
    }
  };

  return (
    <div className="page container-fluid">
      <div className="row justify-content-between align-items-center mt-1">
        <h4 className="page-heading w-auto ps-0">Analytics</h4>
        <div className="d-flex align-items-center w-auto pe-0">
          <button className="button-transparent py-2 px-3 me-1">
            <p className="text-lightBlue">View logs</p>
          </button>
          <button className="button-transparent py-2 px-3 me-1">
            <p className="text-lightBlue">Help?</p>
          </button>
          <div className="button-gradient py-2 px-4">
            <p>Download Reports</p>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-4">
        <div className="d-flex align-items-center analytics-drop">
          <img
            alt="calendar"
            src={calendar}
            height={20}
            className="me-3"
          />
          {timeData.map((item) => (
            <Chip
              key={item.value}
              onClick={() => onTimeFrameChange(item.value)}
              variant={timeFrame === item.value ? "filled" : "outlined"}
              label={item.name}
              className="mx-2"
            />
          ))}
          {!timeFrame && (
            <>
              <div className="mx-2">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    maxDate={moment()}
                    value={moment(fromDate)}
                    onChange={(newValue) => setFromDate(newValue.format("YYYY-MM-DD"))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="mx-2">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    maxDate={moment()}
                    value={moment(toDate)}
                    onChange={(newValue) => setToDate(newValue.format("YYYY-MM-DD"))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
          {/* <Autocomplete
            id="country-select-demo"
            options={countries || []}
            autoHighlight
            size="small"
            disableClearable
            getOptionLabel={(option) => option?.label || ""}
            value={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={"Name"}
              />
            )}
          /> */}
        </div>
        {/* <div className="d-flex align-items-center w-auto pe-0 text-lightBlue">
          <h6>Graph Style</h6>
          <p className="mx-3">
            <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 18 }, color: "#C8D8FF" }} />
            Area
          </p>
          <p>
            <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 18 }, color: "#C8D8FF" }} />
            Line
          </p>
        </div> */}
      </div>

      <div className="d-flex justify-content-between mt-4">
        <div className="row col-12 d-flex justify-content-between">
          <div className="col-lg-7">
            <TotalSales filter={filter} />
            <MostSellingProducts filter={filter} />
            {/* <div className="bg-button-3 border-grey-5 px-3 py-3 rounded-8 mt-4">
              <div className="d-flex row justify-content-between">
                <div className="col-5 mt-1 p-3">
                  <Heading heading={"Total Orders"} />
                  <div className="fs-3 text-blue-gradient fw-600 mt-3">532K</div>
                  <h6 className="text-green-2 me-3 fw-500 mt-2">+80%</h6>
                  <div className="fw-600">
                    <small className="text-blue-gradient">Info: &nbsp;</small>
                    <small className="text-grey-7">80% of the sales come from only</small>
                    <small className="text-blue-2">&nbsp;2 product</small>
                  </div>
                </div>
                <div className="col-7 mt-4">
                  <DoubleLineGraph
                    name={"Total Sale over time"}
                    from={"Jan"}
                    to={"Apr"}
                    fromDate={"22"}
                    toDate={"21"}
                  />
                </div>
              </div>
            </div> */}
            <TopSalesLocations filter={filter} />
            <TopOrders />
          </div>
          <div className="col-lg-5 row">
            <div>
              {/* <div className="bg-button-3 border-grey-5 px-3 py-4 rounded-8 mb-4">
                <Heading heading={"Total Sessions"} />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="fs-3 text-blue-gradient fw-600 mt-3">5.2M</div>
                  <h6 className="text-green-2 me-3 fw-500 mt-2">+20%</h6>
                </div>
                <DoubleLineGraph
                  name={"Total Sessions over time"}
                  from={"Jan"}
                  to={"Apr"}
                  fromDate={"22"}
                  toDate={"21"}
                />
              </div> */}
              <LiveVistor />
              {/* <div className="bg-button-3 border-grey-5 px-3 py-4 rounded-8 mt-4">
                <Heading heading={"Customer"} />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="fs-3 text-blue-gradient fw-600">2.2M</div>
                  <h6 className="text-green-2 me-3 fw-500">+60%</h6>
                </div>
                <DoubleLineGraph
                  name={"Total Customer coversion over time"}
                  from={"Jan"}
                  to={"Apr"}
                  fromDate={"22"}
                  toDate={"21"}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Heading heading={"Returning Customer Rate"} />
                  <div className="d-flex align-items-center">
                    <div className="fs-3 text-blue-gradient fw-600 me-2">70%</div>
                    <h6 className="text-green-2 fw-500">+50%</h6>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <Heading heading={"Conversion Rate"} />
                  <div className="d-flex align-items-center">
                    <div className="fs-3 text-blue-gradient fw-600 me-2">25%</div>
                    <h6 className="text-green-2 fw-500">+80%</h6>
                  </div>
                </div>
              </div> */}
              {/* <div className="bg-button-3 border-grey-5 px-3 py-3 rounded-8 mt-4">
                <Heading
                  heading={"Top Products"}
                />
                <div className="d-flex row justify-content-between">
                  <div className="mt-4 col-12 gap-3">
                    <div className="row">
                      {rows.map((item) => (
                        <div className="col-lg-6 mb-3">
                          <div>
                            <div className="d-flex justify-content-center">
                              <img src={product} />
                            </div>
                          </div>
                          <h5 className="text-lightBlue fw-500 text-center mt-3 mb-1">
                            Super Pink Shoe
                          </h5>
                          <p className="text-lightBlue fw-400 text-center">Rs 2,000</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center py-5">
        <img src={tutorial} />
        <h5 className="text-blue-gradient fw-500 ms-2">Watch the tutorial&nbsp;</h5>
        <h5 className="text-lightBlue">to learn how to use analytics effectively.</h5>
      </div>
    </div>
  );
};

export default Analytics;
