import {
    Tooltip
} from "@mui/material";
import info from "../../assets/icons/info.svg";

const Heading = ({ heading, viewMore, image }) => {

    return (
        <div className="d-flex justify-content-between">
            <div className="d-flex">
                <h6 className="text-lightBlue fw-500 me-2">{heading}</h6>
                <Tooltip title="Lorem ipsum" placement="top">
                    <img
                        src={info}
                        alt="info"
                        className="c-pointer"
                        width={16}
                    />
                </Tooltip>
            </div>
            {viewMore && <>
                <p className="text-blue-gradient fw-600">
                    {viewMore}
                    {image && <img src={image} width={18} className="ms-2" />}
                </p>
            </>
            }
        </div>
    )
};

export default Heading;