import React from "react"; // ! MATERIAL IMPORTS
import { Popover } from "@mui/material";
// ! MATERIAL ICONS IMPORTS
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import ClearIcon from "@mui/icons-material/Clear";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../features/snackbar/snackbarAction";
import CreateShippingLabelDialog from "./CreateShippingLabelDialog";
import { useSendInvoiceMutation } from "../../features/orders/emailers/emailerApiSlice";
import CancelRefundOrderDialog from "./CancelRefundOrderDialog";
import { useUpdateOrderMutation } from "../../features/orders/ordersApiSlice";
import OrderArchiveModal from "./OrderArchiveModal";

const OrderMoreActionsButton = ({ order }) => {
  const dispatch = useDispatch();

  // const [openLabelDialog, setOpenLabelDialog] = React.useState(false);

  // * CONTACT POPOVERS STARTS
  const [anchorContactEl, setContactEl] = React.useState(null);

  const handleContactClick = (event) => {
    setContactEl(event.currentTarget);
  };

  const handleContactClose = () => {
    setContactEl(null);
  };

  const openContact = Boolean(anchorContactEl);
  const idContact = openContact ? "simple-popover" : undefined;
  // * CONTACT POPOVERS ENDS

  const [showArchiveModal, setShowArchiveModal] = React.useState(false);

  const [editOrder, { isLoading: editOrderIsLoading }] = useUpdateOrderMutation();

  const archiveOrder = () => {
    editOrder({ id: order?._id, order: { status: "archieved" } })
      .unwrap()
      .then(() => {
        dispatch(showSuccess({ message: "Order Archieved Succesfully" }));
        setShowArchiveModal(false);
      })
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
  };

  const [sendInvoice, { isLoading: sendInvoiceIsLoading }] = useSendInvoiceMutation();

  const sendInvoiceOnClick = () => {
    sendInvoice({
      order: order?._id,
      customerEmail: order?.address?.shipping?.email,
    })
      .unwrap()
      .then(() => {
        dispatch(showSuccess({ message: "Invoice Sent Succesfully" }));
      })
      .catch((e) => {
        dispatch(showError({ message: e?.data?.message ?? "Something went wrong" }));
      });
  };

  const downloadInvoice = async (url) => {
    try {
      const data = await fetch(`${url}?t=${Date.now().toString(36)}`);
      if (!data.ok)
        throw new Error(`Download Failed status:${data.status} message:${data.statusText}`);
      const blob = await data.blob();
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", objectUrl);
      link.setAttribute("download", "invoices.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.log(error);
      dispatch(showError({ message: error.message ?? "Something went wrong" }));
    }
  };

  const [cancellingOrder, setCancellingOrder] = React.useState(null);

  return (
    <React.Fragment>
      <button
        className="button-gradient py-1 px-4 w-auto me-3"
        onClick={handleContactClick}>
        <p>More Actions</p>
      </button>

      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id={idContact}
        open={openContact}
        anchorEl={anchorContactEl}
        onClick={handleContactClose}
        onClose={handleContactClose}>
        <div className="py-2 px-1">
          {(order?.status === "pending" || order?.status === "processing") && (
            <div
              onClick={() => setCancellingOrder(order)}
              className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
              <ClearIcon
                sx={{
                  color: "#c8d8ff",
                  fontSize: 16,
                  cursor: "pointer",
                  marginRight: "0.5rem",
                }}
              />
              <small className="text-lightBlue d-block">Cancel Order</small>
            </div>
          )}
          {!!order?.invoiceUrl && (
            <>
              <div
                onClick={() => downloadInvoice(order.invoiceUrl)}
                className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
                <FileDownloadOutlinedIcon
                  sx={{
                    color: "#c8d8ff",
                    fontSize: 16,
                    cursor: "pointer",
                    marginRight: "0.5rem",
                  }}
                />
                <small className="text-lightBlue d-block">Download Order Invoice</small>
              </div>
              <div
                onClick={sendInvoiceOnClick}
                className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
                <EmailOutlinedIcon
                  sx={{
                    color: "#c8d8ff",
                    fontSize: 16,
                    cursor: "pointer",
                    marginRight: "0.5rem",
                  }}
                />
                <small className="text-lightBlue d-block">Send Invoice Email</small>
              </div>
            </>
          )}
          {(order?.status === "cancelled" || order?.status === "delivered") && (
            <div
              onClick={() => setShowArchiveModal(true)}
              className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
              <DeleteIcon
                sx={{
                  color: "#c8d8ff",
                  fontSize: 16,
                  cursor: "pointer",
                  marginRight: "0.5rem",
                }}
              />
              <small className="text-lightBlue d-block">Archive Order</small>
            </div>
          )}
        </div>
      </Popover>

      <CancelRefundOrderDialog
        order={cancellingOrder}
        onClose={setCancellingOrder}
      />

      <OrderArchiveModal
        show={showArchiveModal}
        onConfirm={archiveOrder}
        onCancel={() => setShowArchiveModal(false)}
        title="Archive Order"
        message="Are you sure you want to Archive this Order?"
        isLoading={editOrderIsLoading}
      />
    </React.Fragment>
  );
};

export default OrderMoreActionsButton;

// <Popover
//   anchorOrigin={{
//     vertical: "bottom",
//     horizontal: "left",
//   }}
//   transformOrigin={{
//     vertical: "top",
//     horizontal: "left",
//   }}
//   id={idContact}
//   open={openContact}
//   anchorEl={anchorContactEl}
//   onClick={handleContactClose}
//   onClose={handleContactClose}>
//   <div className="py-2 px-1">
//     <div
//       onClick={() => setOpenLabelDialog(true)}
//       className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
//       <ReceiptOutlinedIcon
//         sx={{
//           color: "#c8d8ff",
//           fontSize: 16,
//           cursor: "pointer",
//           marginRight: "0.5rem",
//         }}
//       />
//       <small className="text-lightBlue d-block">Create Shipping Label</small>
//     </div>
//     {(order?.status === "onHold" || order?.status === "processing") && (
//       <div
//         onClick={() => setCancellingOrder(order)}
//         className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
//         <ClearIcon
//           sx={{
//             color: "#c8d8ff",
//             fontSize: 16,
//             cursor: "pointer",
//             marginRight: "0.5rem",
//           }}
//         />
//         <small className="text-lightBlue d-block">Cancel and Refund</small>
//       </div>
//     )}
//     {!!order?.invoiceUrl && (
//       <>
//         <a
//           href={order.invoiceUrl}
//           target="_blank"
//           rel="noreferrer noopener"
//           className="d-flex align-items-center text-decoration-none rounded-3 p-2 hover-back c-pointer">
//           <ReceiptLongOutlinedIcon
//             sx={{
//               color: "#c8d8ff",
//               fontSize: 16,
//               cursor: "pointer",
//               marginRight: "0.5rem",
//             }}
//           />
//           <small className="text-lightBlue d-block">View Invoice</small>
//         </a>
//         <div
//           onClick={() => downloadInvoice(order.invoiceUrl)}
//           className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
//           <FileDownloadOutlinedIcon
//             sx={{
//               color: "#c8d8ff",
//               fontSize: 16,
//               cursor: "pointer",
//               marginRight: "0.5rem",
//             }}
//           />
//           <small className="text-lightBlue d-block">Download Order Invoice</small>
//         </div>
//         <div
//           onClick={sendInvoiceOnClick}
//           className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
//           <EmailOutlinedIcon
//             sx={{
//               color: "#c8d8ff",
//               fontSize: 16,
//               cursor: "pointer",
//               marginRight: "0.5rem",
//             }}
//           />
//           <small className="text-lightBlue d-block">Send Invoice Email</small>
//         </div>
//       </>
//     )}
//     <div className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
//       <PrintIcon
//         sx={{
//           color: "#c8d8ff",
//           fontSize: 16,
//           cursor: "pointer",
//           marginRight: "0.5rem",
//         }}
//       />
//       <small className="text-lightBlue d-block">Print Packaging Slip</small>
//     </div>
//     <div className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
//       <StarBorderOutlinedIcon
//         sx={{
//           color: "#c8d8ff",
//           fontSize: 16,
//           cursor: "pointer",
//           marginRight: "0.5rem",
//         }}
//       />
//       <small className="text-lightBlue d-block">Mark as Priority Order</small>
//     </div>
//     {(order?.status === "cancelled" ||
//       order?.status === "delivered" ||
//       order?.status === "fullfiled") && (
//       <div
//         onClick={() => setShowArchiveModal(true)}
//         className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
//         <DeleteIcon
//           sx={{
//             color: "#c8d8ff",
//             fontSize: 16,
//             cursor: "pointer",
//             marginRight: "0.5rem",
//           }}
//         />
//         <small className="text-lightBlue d-block">Archive Order</small>
//       </div>
//     )}
//   </div>
// </Popover>
