import React, { useReducer, useState } from "react";
import { Link, useParams } from "react-router-dom";
// ! COMPONENT IMPORTS
import {
  EnhancedTableHead,
  stableSort,
  getComparator,
} from "../../../components/TableDependencies/TableDependencies";
import TableEditStatusButton from "../../../components/TableEditStatusButton/TableEditStatusButton";
import TableMassActionButton from "../../../components/TableMassActionButton/TableMassActionButton";
import AppCountrySelect from "../../../components/AppCountrySelect/AppCountrySelect";
import ProductDrawerTable from "../ProductDrawerTable";
import TableSearch, { TableSearchSecondary } from "../../../components/TableSearch/TableSearch";
// !IMAGES IMPORTS
import product2 from "../../../assets/images/products/product2.jpg";
import teamMember1 from "../../../assets/images/products/teamMember1.svg";
import teamMember2 from "../../../assets/images/products/teamMember2.svg";
import teamMember3 from "../../../assets/images/products/teamMember3.svg";
import activity from "../../../assets/icons/activity.svg";
import cancel from "../../../assets/icons/cancel.svg";
import products from "../../../assets/icons/sidenav/products.svg";
import arrowDown from "../../../assets/icons/arrowDown.svg";
// ! MATERIAL IMPORTS
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Popover,
  SwipeableDrawer,
  TextField,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Paper,
  TableHead,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
// ! MATERIAL ICONS IMPORTS
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import {
  useGetAllInventoryProductsQuery,
  useGetAllProductsInventoryQuery,
} from "../../../features/products/product/inventoryAPISlice";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import TableLoader from "../../../components/Loader/TableLoader";
import { useGetAllVariantsQuery } from "../../../features/products/product/varianceApiSlice";
import VariantsSideDrawer from "./VariantsSideDrawer";

const headCells = [
  {
    id: "products",
    numeric: false,
    disablePadding: false,
    label: "Products",
  },
  {
    id: "variants",
    numeric: false,
    disablePadding: false,
    label: "Variants",
  },
  {
    id: "qty",
    numeric: false,
    disablePadding: false,
    label: "Total Qty",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Add Quantity",
  },
];

const initialQueries = {
  title: "",
  pageNo: 1,
  pageSize: 10,
};

const queryReducer = (state, action) => {
  switch (action.type) {
    case "SET_TITLE":
      return {
        ...initialQueries,
        title: action.title,
      };
    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageNo: 1,
        pageSize: action.pageSize,
      };

    case "SET_PAGE_NO":
      return {
        ...state,
        pageNo: action.pageNo,
      };

    default:
      return state;
  }
};

const ProductInventoryDetailsTable = () => {
  const { storeId } = useParams();

  const [viewingInventory, setViewingInventory] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [queryFilters, dispatchQueryFilters] = useReducer(queryReducer, initialQueries);

  const { data: allInventoryData, isLoading: allInventoryIsLoading } =
    useGetAllProductsInventoryQuery({ storeId, queries: queryFilters });

  const inventoryData = allInventoryData?.data ?? [];

  return (
    <Paper className="border-grey-5 bg-black-15">
      <div className="d-flex align-items-center my-3 px-2 justify-content-between">
        <TableSearchSecondary
          value={searchKey}
          onChange={(val) => dispatchQueryFilters({ type: "SET_TITLE", title: val })}
          onSearchValueChange={(val) => setSearchKey(val)}
        />
      </div>

      {allInventoryIsLoading ? (
        <TableLoader />
      ) : inventoryData.length === 0 ? (
        <NoDataFound />
      ) : (
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => {
                  return (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}>
                      <p className="text-lightBlue">{headCell.label}</p>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {inventoryData.map((row) => {
                const {
                  _id: rid = "",
                  title = "",
                  variants = 0,
                  totalQty = 0,
                  mediaUrl = [],
                  // inventories = [],
                  inventory: { skuId = "" } = {},
                } = row ?? {};
                // const quantityCount = inventories?.[0]?.store?.quantityCount ?? 0;
                const imageUrl = mediaUrl.find((mu) => mu.isDefault)?.image ?? "";

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={rid}
                    className="table-rows">
                    <TableCell
                      component="th"
                      scope="row"
                      padding="normal"
                      className="py-1">
                      <div className="d-flex align-items-center py-3">
                        <AsyncImage
                          src={imageUrl}
                          placeholder={product2}
                          alt="product"
                          className="me-2 rounded-4"
                          height={45}
                          width={45}
                        />
                        <div>
                          <p className="text-lightBlue rounded-circle fw-600">{title}</p>
                          <small className="text-grey-6 mt-1">SKU: {skuId}</small>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ width: 180 }}
                      padding="normal"
                      className="py-1">
                      <p className="text-lightBlue">{variants}</p>
                    </TableCell>
                    <TableCell
                      style={{ width: 140 }}
                      padding="normal"
                      className="py-1">
                      <p className="text-lightBlue">{totalQty}</p>
                    </TableCell>
                    <TableCell
                      style={{ width: 140 }}
                      padding="normal"
                      className="py-1">
                      <div className="d-flex align-items-center">
                        <button
                          onClick={() => setViewingInventory(row)}
                          className="button-transparent border-grey-5 py-2 px-3">
                          <AddCircleIcon sx={{ color: "#c8d8ff", fontSize: 18 }} />
                          <p className="text-lightBlue ms-2">Add</p>
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={inventoryData.length}
        rowsPerPage={queryFilters.pageSize}
        page={queryFilters.pageNo - 1}
        onPageChange={(_, val) =>
          dispatchQueryFilters({ type: "SET_PAGE_NO", pageNo: val + 1 })
        }
        onRowsPerPageChange={(e) =>
          dispatchQueryFilters({ type: "SET_PAGE_SIZE", pageSize: e.target.value })
        }
        className="table-pagination"
      />
      <VariantsSideDrawer
        storeId={storeId}
        inventory={viewingInventory}
        isOpen={!!viewingInventory}
        onClose={() => setViewingInventory(null)}
      />
    </Paper>
  );
};

export default ProductInventoryDetailsTable;

// {
//   id: "activity",
//   numeric: false,
//   disablePadding: false,
//   label: "Activity",
// },

/* <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
          /> */

/*selected.length > 0 && (
        <div className="d-flex align-items-center px-2 mb-3">
          <button className="button-grey py-2 px-3">
            <small className="text-lightBlue">
              {selected.length} products are selected&nbsp;
              <span
                className="text-blue-2 c-pointer"
                onClick={() => setSelected([])}>
                (Clear Selection)
              </span>
            </small>
          </button>
          <TableEditStatusButton />
          <TableMassActionButton />
        </div>
      )*/

/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                      onClick={(event) => handleClick(event, row.sId)}
                      size="small"
                      style={{
                        color: "#5C6D8E",
                      }}
                    />
                  </TableCell> */

/* <TableCell
                      padding="normal"
                      className="py-1">
                      <div
                        className="d-flex c-pointer"
                        // onClick={toggleActivityDrawer("right", true)}
                        // onMouseEnter={handleActivityPopoverOpen}
                        // onMouseLeave={handleActivityPopoverClose}
                      >
                        <img
                          src={teamMember1}
                          alt="teamMember1"
                        />
                        <img
                          src={teamMember3}
                          alt="teamMember2"
                        />
                        <img
                          src={teamMember2}
                          alt="teamMember3"
                        />
                      </div>
                    </TableCell> */
