import { useMemo, useState } from "react";
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import Heading from "../../components/Headers/DashboardHeader";
import {
  useGetTopSellingCategoryAnalyticsQuery,
  useGetTopSellingCollectionAnalyticsQuery,
} from "../../features/analytics/insightsApiSlice";
import {
  useGetPageTrafficQuery,
  useGetTopDevicesUsedQuery,
} from "../../features/analytics/analyticsApiSlice";
import moment from "moment/moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import NoDataFound from "../../components/NoDataFound/NoDataFound";

const timeData = [
  { value: "week", name: "This Week" },
  { value: "month", name: "This Month" },
  { value: "year", name: "This Year" },
  { value: "", name: "Custom" },
];

const nowMoment = moment();

export default function TopPageVisit() {
  const [timeFrame, setTimeFrame] = useState("week");
  const [fromDate, setFromDate] = useState(moment().subtract(1, "week").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(nowMoment.format("YYYY-MM-DD"));

  const filter = useMemo(() => {
    if (!timeFrame) return { to: toDate, from: fromDate };
    else return { type: timeFrame };
  }, [fromDate, timeFrame, toDate]);

  const { data = [] } = useGetPageTrafficQuery(filter);

  const onTimeFrameChange = (val) => {
    switch (val) {
      case "week":
        setTimeFrame("week");
        setFromDate(moment().subtract(1, "week").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;
      case "month":
        setTimeFrame("month");
        setFromDate(moment().subtract(1, "month").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;
      case "year":
        setTimeFrame("year");
        setFromDate(moment().subtract(1, "year").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;

      default:
        setTimeFrame("");
        break;
    }
  };

  return (
    <div className="col-12 mt-4">
      <div className="bg-button-3 border-grey-5 rounded-8 px-4 pt-4 pb-3">
        <Heading
          heading={"Top Pages"}
          // viewMore={"View More"}
        />
        <div className="d-flex align-items-center my-3">
          {timeData.map((item) => (
            <Chip
              key={item.value}
              onClick={() => onTimeFrameChange(item.value)}
              variant={timeFrame === item.value ? "filled" : "outlined"}
              label={item.name}
              className="mx-2"
            />
          ))}
          {!timeFrame && (
            <>
              <div className="mx-2">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    maxDate={moment()}
                    value={moment(fromDate)}
                    onChange={(newValue) => setFromDate(newValue.format("YYYY-MM-DD"))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="mx-2">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    maxDate={moment()}
                    value={moment(toDate)}
                    onChange={(newValue) => setToDate(newValue.format("YYYY-MM-DD"))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
        </div>
        <TableContainer>
          {data.length > 0 ? (
            <Table>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.page}>
                    <TableCell
                      component="th"
                      scope="row">
                      <small className="text-capitalize text-blue-gradient-2 fw-600">
                        {row.page}
                      </small>
                    </TableCell>
                    <TableCell align="right">
                      <small className="text-lightBlue fw-400">{row.currentValue} Visits</small>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoDataFound />
          )}
        </TableContainer>
      </div>
    </div>
  );
}
